// Imports
@import '../../../resources/styles/colors';

/**  =====================
  Spinning
==========================  **/

.loading-container {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  .logo {
    width: 200px;
    height: 59px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../resources/images/themes/main/logo-horizontal.svg');
    margin: auto;
  }
}

.image-loader {
  position: relative;
  height: 80px;
  width: 200px;
  margin: 200px auto auto;
}

.image-loader img {
  width: 200px;
}

.image-loader .lds-grid {
  margin-top: 20px;
}

.loader-text {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  font-family: Ubuntu;
}

#circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
}

.loader {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 8px transparent;
  border-top: 8px solid $primary-blue;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/**  =====================
  Grid
==========================  **/

.lds-grid {
  position: relative;
  height: 80px;
  width: 80px;
  margin: 200px auto auto;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
