@import "../../../resources/styles/colors";

.ant-layout,
.ant-modal-root,
.ant-modal-content {
  .float-label-container {
    position: relative;
    margin-bottom: 0;

    .label {
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 6px;
      top: 0;
      transition: 0.2s ease all;
      color: $grey;
    }

    .label-float {
      left: 4px;
      top: -18px;
      font-size: 12px;
    }

    .ant-radio-group.ant-radio-group-outline ~ label.input-label {
      top: -30px;
    }
  }
}
