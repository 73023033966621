.theme-tester {
  position: fixed;
  right: 10px;
  z-index: 10000;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-select {
    width: 100%;
  }
}
