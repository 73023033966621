.ant-form-item-control {
  .file-input {
    height: 75px;
  
    .ant-upload.ant-upload-drag {
      .ant-upload-btn {
        padding: 0;
      }
  
      &.small {
        background-color: #00255b;
        border: none;
        height: 40px;
  
        .ant-upload-drag-container {
          color: #fff;
        }
      }
    }
  }
  
  &.has-error {
    .file-input {
      .ant-upload-list-item {
        margin-top: 15px;
      }
    }
  }
}
