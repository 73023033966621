
.sna-filters {
  .ant-card {
    background-color: #ffffff;
    margin-bottom: 25px;
    border-radius: 4px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header,
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    margin-bottom: 20px;
  }

  .table-filters {
    margin-bottom: 0;
  }
}