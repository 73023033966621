// Imports

@import '../../resources/styles/colors';
@import '../../resources/styles/mixins';

// =============================================================================
// TABLES
// =============================================================================

.ant-table-wrapper {
  table {
    color: #000;

    thead {
      th {
        border-bottom: 1px solid #f6f6f6;
        background-color: transparent;
        padding: 21px 6px;

        .ant-table-column-title {
          font-size: pxToRem(16);
          font-weight: bold;
        }

        &.text-center {
          span {
            text-align: center;
            width: 100%;
          }
        }
      }

      .networks {
        text-align: center;
      }
    }

    tbody {
      td {
        border-bottom: 1px solid #d2d2d1;
        padding: 14px 8px;
      }

      i {
        font-size: 24px;
      }

      .table-actions {
        justify-content: center;
        @include flexbox;

        button {
          i {
            font-size: 30px;
          }
        }

        button + button {
          margin-left: 15px;
        }
      }

      // EXTERNAL TABLE ELEMENTS
      .ant-avatar {
        height: 57px !important;
        width: 57px !important;
      }

      .img-country {
        width: 27px;
      }

      .brand-logo {
        border-radius: 50px;
        height: 55px;
        width: 55px;
      }

      .avatars-list {
        img {
          border-radius: 50%;
          height: 57px;
          width: 57px;

          &:nth-child(2) {
            position: relative;
            left: -15px;
          }
        }
      }

      p {
        margin-bottom: 0;
      }

      .networks {
        text-align: center;

        i {
          margin-right: 5px;
          font-size: 18px;

          &:last-of-type {
            margin: 0;
          }
        }
      }

      .dropdown-container {
        justify-content: flex-end;
        align-items: center;
        display: flex;

        .ant-btn-icon-only.ant-btn-lg {
          width: auto;
          height: auto;

          i {
            font-size: 20px;
            margin-top: 10px;
            color: #747474;
          }

          &:hover {
            i {
              color: $primary-blue;
            }
          }
        }

        .dropdown-options {
          transform: rotate(90deg);
          margin: 0px 5px 0 10px;
        }
      }

      i.anticon-right {
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}

// PAGINATION
.table-pagination {
  padding: 30px 0;
  margin: 0;

  .ant-pagination {
    text-align: right;

    .ant-pagination-item {
      border-color: $primary-blue;

      a {
        color: $primary-blue;
      }
    }

    .ant-select-selection {
      padding-left: 10px;
      padding-right: 30px;
      padding-top: 1px;

      &:hover {
        border-color: $light-blue;
      }
    }
  }
}

.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}

.ant-table-body {
  overflow-x: scroll;
}
