@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap);
@import url(https://fonts.cdnfonts.com/css/gotham);
/* Ant Design Overrides */
.ant-dropdown {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
  width: 184px; }
  .ant-dropdown .ant-dropdown-menu {
    padding: 0; }
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
      border-bottom: solid 1px rgba(216, 216, 216, 0.3);
      font-family: 'Ubuntu', sans-serif;
      -webkit-align-items: center;
              align-items: center;
      font-weight: 500;
      padding: 8px 7px;
      font-size: 12px;
      display: -webkit-flex;
      display: flex;
      color: black; }
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item i {
        margin-right: 5px;
        font-size: 20px;
        color: #E1198E; }
        .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item i.icon-trash {
          margin-right: 8px;
          margin-left: 4px; }
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item img {
        margin-right: 5px;
        width: 25px; }
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-of-type {
        border: none; }
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background-color: #f6f6f6; }

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
  background: #1442f9 !important;
  line-height: 1.4;
  height: auto;
  width: auto;
  border-radius: 0;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px; }

.ant-calendar-range .ant-calendar-disabled-cell .ant-calendar-date:hover {
  color: #fff; }

.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #1442f9 !important;
  line-height: 1.4;
  height: auto;
  width: auto;
  border-radius: 0;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px; }

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  box-shadow: none; }

.ant-calendar-picker-container {
  margin-top: 10px;
  z-index: 10000 !important; }

.ant-calendar-range-picker-input {
  text-align: left !important; }

.ant-calendar-date:hover {
  background: #1442f9 !important;
  color: #fff; }

.ant-calendar-range-picker-separator {
  display: none !important; }

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #d1dafe !important; }

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #1442f9 !important;
  box-shadow: none !important; }

/* INPUT */
.ant-layout .ant-form-item,
.ant-modal-content .ant-form-item,
.ant-modal-root .ant-form-item {
  margin-bottom: 30px; }
  .ant-layout .ant-form-item.slider-input.has-value .input-label,
  .ant-modal-content .ant-form-item.slider-input.has-value .input-label,
  .ant-modal-root .ant-form-item.slider-input.has-value .input-label {
    font-size: 12px;
    -webkit-transform: translateY(-85px);
            transform: translateY(-85px); }
  .ant-layout .ant-form-item .ant-checkbox-wrapper span,
  .ant-modal-content .ant-form-item .ant-checkbox-wrapper span,
  .ant-modal-root .ant-form-item .ant-checkbox-wrapper span {
    display: inline-block;
    line-height: normal; }
    .ant-layout .ant-form-item .ant-checkbox-wrapper span a,
    .ant-modal-content .ant-form-item .ant-checkbox-wrapper span a,
    .ant-modal-root .ant-form-item .ant-checkbox-wrapper span a {
      color: #1442f9; }
  .ant-layout .ant-form-item .ant-checkbox-wrapper .ant-checkbox-checked:after,
  .ant-modal-content .ant-form-item .ant-checkbox-wrapper .ant-checkbox-checked:after,
  .ant-modal-root .ant-form-item .ant-checkbox-wrapper .ant-checkbox-checked:after {
    border: none; }
  .ant-layout .ant-form-item .ant-radio-group,
  .ant-modal-content .ant-form-item .ant-radio-group,
  .ant-modal-root .ant-form-item .ant-radio-group {
    width: 100%; }
    .ant-layout .ant-form-item .ant-radio-group .ant-radio-button-wrapper,
    .ant-modal-content .ant-form-item .ant-radio-group .ant-radio-button-wrapper,
    .ant-modal-root .ant-form-item .ant-radio-group .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
      color: #979797;
      height: 32px;
      border: 1px solid #979797; }
      .ant-layout .ant-form-item .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-modal-content .ant-form-item .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-modal-root .ant-form-item .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        border: 1px solid; }

.ant-layout .full-phone-field .ant-col.code-container .flag-image,
.ant-modal-content .full-phone-field .ant-col.code-container .flag-image,
.ant-modal-root .full-phone-field .ant-col.code-container .flag-image {
  width: 15px;
  position: absolute;
  top: 13px;
  z-index: 2; }

.ant-layout .full-phone-field .ant-col.code-container .ant-form-item-control .ant-form-item-children .ant-input,
.ant-modal-content .full-phone-field .ant-col.code-container .ant-form-item-control .ant-form-item-children .ant-input,
.ant-modal-root .full-phone-field .ant-col.code-container .ant-form-item-control .ant-form-item-children .ant-input {
  text-align: center; }

.ant-layout .full-phone-field .separator span,
.ant-modal-content .full-phone-field .separator span,
.ant-modal-root .full-phone-field .separator span {
  top: 8px;
  position: absolute; }

/* MODAL */
body .ant-select-dropdown {
  z-index: 100000; }

body .ant-modal-root .ant-modal-confirm {
  text-align: center; }
  body .ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
    float: inherit; }
    body .ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn {
      font-weight: bold;
      border-color: #E1198E;
      color: #E1198E; }
      body .ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
        color: #fff; }
    body .ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-danger {
      background-color: #E1198E;
      color: white; }
      body .ant-modal-root .ant-modal-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns .ant-btn-danger:hover {
        background-color: #a6a5a3;
        color: white; }

body .ant-modal-mask {
  z-index: 10000; }

body .ant-modal-wrap {
  z-index: 10000; }
  body .ant-modal-wrap .ant-modal {
    top: 130px; }
    @media screen and (max-width: 480px) {
      body .ant-modal-wrap .ant-modal {
        width: 100% !important;
        max-width: 100vw !important;
        margin: 0;
        padding: 0;
        height: 100%;
        top: 0; }
        body .ant-modal-wrap .ant-modal .ant-modal-content {
          border-radius: 0;
          min-height: 100vh; } }
    body .ant-modal-wrap .ant-modal .ant-modal-close,
    body .ant-modal-wrap .ant-modal .ant-modal-close .ant-modal-close-x {
      line-height: 50px;
      height: 50px;
      width: 50px; }
    body .ant-modal-wrap .ant-modal .ant-modal-body {
      padding: 0; }
      body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card {
        border: none;
        border-radius: 5px; }
        @media screen and (max-width: 480px) {
          body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card {
            border-radius: 0; } }
        body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head,
        body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head .ant-card-head-title {
          padding: 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px; }
          @media screen and (max-width: 480px) {
            body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head,
            body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head .ant-card-head-title {
              border-radius: 0; } }
        body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head {
          border: none; }
          @media screen and (max-width: 480px) {
            body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-head {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); } }
        @media screen and (max-width: 576px) {
          body .ant-modal-wrap .ant-modal .ant-modal-body .ant-card .ant-card-body {
            padding: 10px; } }
      body .ant-modal-wrap .ant-modal .ant-modal-body .header {
        border-radius: 0; }
      body .ant-modal-wrap .ant-modal .ant-modal-body .modal-header {
        padding: 18px 18px 18px 50px;
        background: linear-gradient(to left, #380067 0%, #9c1daf 100%);
        color: #fff; }

body .ant-card > .ant-card-head-title {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

body .ant-notification {
  z-index: 1000000; }

body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
  padding-top: 20px; }
  body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon-question-circle {
    display: none; }
  body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-content {
    color: #000; }

body .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  margin-top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px; }

/* Slider */
.ant-layout .ant-slider .ant-slider-rail,
.ant-layout .ant-slider:hover .ant-slider-rail {
  height: 12px; }

.ant-layout .ant-slider .ant-slider-track,
.ant-layout .ant-slider:hover .ant-slider-track {
  background-color: #1442f9;
  height: 12px; }

.ant-layout .ant-slider .ant-slider-step,
.ant-layout .ant-slider:hover .ant-slider-step {
  height: 12px; }
  .ant-layout .ant-slider .ant-slider-step .ant-slider-dot,
  .ant-layout .ant-slider:hover .ant-slider-step .ant-slider-dot {
    height: 12px;
    width: 12px;
    top: 0; }

.ant-layout .ant-slider .ant-slider-handle,
.ant-layout .ant-slider .ant-slider-handle:not(.ant-tooltip-open),
.ant-layout .ant-slider:hover .ant-slider-handle,
.ant-layout .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  height: 24px;
  width: 24px;
  border: 3px solid #1442f9; }

.ant-layout .ant-slider .ant-slider-mark,
.ant-layout .ant-slider:hover .ant-slider-mark {
  top: 20px; }
  .ant-layout .ant-slider .ant-slider-mark .ant-slider-mark-text,
  .ant-layout .ant-slider:hover .ant-slider-mark .ant-slider-mark-text {
    left: 97% !important;
    width: 200px; }
    .ant-layout .ant-slider .ant-slider-mark .ant-slider-mark-text:first-child,
    .ant-layout .ant-slider:hover .ant-slider-mark .ant-slider-mark-text:first-child {
      left: 3% !important; }

.ant-descriptions .ant-descriptions-item {
  padding: 0 30px 10px 0;
  vertical-align: top;
  text-align: justify; }
  .ant-descriptions .ant-descriptions-item .ant-descriptions-item-label {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize; }
  .ant-descriptions .ant-descriptions-item .ant-descriptions-item-content {
    margin-bottom: 14px; }

/* Tags Collapse, MISC */
.ant-layout .ant-collapse {
  background-color: transparent;
  border: none; }
  .ant-layout .ant-collapse .ant-collapse-item {
    border: none; }
    .ant-layout .ant-collapse .ant-collapse-item .ant-collapse-header {
      border: none;
      font-size: 14px;
      font-weight: bold; }
    .ant-layout .ant-collapse .ant-collapse-item .ant-collapse-content {
      border: none; }

.ant-layout .addon-before.ant-input-group-wrapper .ant-input-group input {
  padding-left: 30px; }

.ant-layout .addon-after.ant-input-group-wrapper .ant-input-group-addon {
  right: 20px; }

.ant-layout .addon-after.ant-input-group-wrapper .ant-input-group input {
  padding-right: 30px; }

.ant-layout .ant-input-group {
  position: relative; }

.ant-layout .ant-input-group.ant-input-group-compact > :first-child,
.ant-layout .ant-input-group.ant-input-group-compact > :last-child {
  border-radius: 0; }

.ant-tooltip {
  z-index: 100000 !important; }

.mt-auto {
  margin-top: autopx !important; }

.pt-auto {
  padding-top: autopx !important; }

.mb-auto {
  margin-bottom: autopx !important; }

.pb-auto {
  padding-bottom: autopx !important; }

.ml-auto {
  margin-left: autopx !important; }

.pl-auto {
  padding-left: autopx !important; }

.mr-auto {
  margin-right: autopx !important; }

.pr-auto {
  padding-right: autopx !important; }

.mc-auto {
  margin-center: autopx !important; }

.pc-auto {
  padding-center: autopx !important; }

.m-auto {
  margin: autopx !important; }

.p-auto {
  padding: autopx !important; }

.mt--20 {
  margin-top: -20px !important; }

.pt--20 {
  padding-top: -20px !important; }

.mb--20 {
  margin-bottom: -20px !important; }

.pb--20 {
  padding-bottom: -20px !important; }

.ml--20 {
  margin-left: -20px !important; }

.pl--20 {
  padding-left: -20px !important; }

.mr--20 {
  margin-right: -20px !important; }

.pr--20 {
  padding-right: -20px !important; }

.mc--20 {
  margin-center: -20px !important; }

.pc--20 {
  padding-center: -20px !important; }

.m--20 {
  margin: -20px !important; }

.p--20 {
  padding: -20px !important; }

.mt--15 {
  margin-top: -15px !important; }

.pt--15 {
  padding-top: -15px !important; }

.mb--15 {
  margin-bottom: -15px !important; }

.pb--15 {
  padding-bottom: -15px !important; }

.ml--15 {
  margin-left: -15px !important; }

.pl--15 {
  padding-left: -15px !important; }

.mr--15 {
  margin-right: -15px !important; }

.pr--15 {
  padding-right: -15px !important; }

.mc--15 {
  margin-center: -15px !important; }

.pc--15 {
  padding-center: -15px !important; }

.m--15 {
  margin: -15px !important; }

.p--15 {
  padding: -15px !important; }

.mt--10 {
  margin-top: -10px !important; }

.pt--10 {
  padding-top: -10px !important; }

.mb--10 {
  margin-bottom: -10px !important; }

.pb--10 {
  padding-bottom: -10px !important; }

.ml--10 {
  margin-left: -10px !important; }

.pl--10 {
  padding-left: -10px !important; }

.mr--10 {
  margin-right: -10px !important; }

.pr--10 {
  padding-right: -10px !important; }

.mc--10 {
  margin-center: -10px !important; }

.pc--10 {
  padding-center: -10px !important; }

.m--10 {
  margin: -10px !important; }

.p--10 {
  padding: -10px !important; }

.mt--5 {
  margin-top: -5px !important; }

.pt--5 {
  padding-top: -5px !important; }

.mb--5 {
  margin-bottom: -5px !important; }

.pb--5 {
  padding-bottom: -5px !important; }

.ml--5 {
  margin-left: -5px !important; }

.pl--5 {
  padding-left: -5px !important; }

.mr--5 {
  margin-right: -5px !important; }

.pr--5 {
  padding-right: -5px !important; }

.mc--5 {
  margin-center: -5px !important; }

.pc--5 {
  padding-center: -5px !important; }

.m--5 {
  margin: -5px !important; }

.p--5 {
  padding: -5px !important; }

.mt-0 {
  margin-top: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.mc-0 {
  margin-center: 0px !important; }

.pc-0 {
  padding-center: 0px !important; }

.m-0 {
  margin: 0px !important; }

.p-0 {
  padding: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.pt-5 {
  padding-top: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.pl-5 {
  padding-left: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.pr-5 {
  padding-right: 5px !important; }

.mc-5 {
  margin-center: 5px !important; }

.pc-5 {
  padding-center: 5px !important; }

.m-5 {
  margin: 5px !important; }

.p-5 {
  padding: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.mc-10 {
  margin-center: 10px !important; }

.pc-10 {
  padding-center: 10px !important; }

.m-10 {
  margin: 10px !important; }

.p-10 {
  padding: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.pt-15 {
  padding-top: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.pl-15 {
  padding-left: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.mc-15 {
  margin-center: 15px !important; }

.pc-15 {
  padding-center: 15px !important; }

.m-15 {
  margin: 15px !important; }

.p-15 {
  padding: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.mc-20 {
  margin-center: 20px !important; }

.pc-20 {
  padding-center: 20px !important; }

.m-20 {
  margin: 20px !important; }

.p-20 {
  padding: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.pt-25 {
  padding-top: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.pl-25 {
  padding-left: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.pr-25 {
  padding-right: 25px !important; }

.mc-25 {
  margin-center: 25px !important; }

.pc-25 {
  padding-center: 25px !important; }

.m-25 {
  margin: 25px !important; }

.p-25 {
  padding: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.mc-30 {
  margin-center: 30px !important; }

.pc-30 {
  padding-center: 30px !important; }

.m-30 {
  margin: 30px !important; }

.p-30 {
  padding: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.pt-35 {
  padding-top: 35px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.ml-35 {
  margin-left: 35px !important; }

.pl-35 {
  padding-left: 35px !important; }

.mr-35 {
  margin-right: 35px !important; }

.pr-35 {
  padding-right: 35px !important; }

.mc-35 {
  margin-center: 35px !important; }

.pc-35 {
  padding-center: 35px !important; }

.m-35 {
  margin: 35px !important; }

.p-35 {
  padding: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.mc-40 {
  margin-center: 40px !important; }

.pc-40 {
  padding-center: 40px !important; }

.m-40 {
  margin: 40px !important; }

.p-40 {
  padding: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.pt-45 {
  padding-top: 45px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.ml-45 {
  margin-left: 45px !important; }

.pl-45 {
  padding-left: 45px !important; }

.mr-45 {
  margin-right: 45px !important; }

.pr-45 {
  padding-right: 45px !important; }

.mc-45 {
  margin-center: 45px !important; }

.pc-45 {
  padding-center: 45px !important; }

.m-45 {
  margin: 45px !important; }

.p-45 {
  padding: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.pl-50 {
  padding-left: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.pr-50 {
  padding-right: 50px !important; }

.mc-50 {
  margin-center: 50px !important; }

.pc-50 {
  padding-center: 50px !important; }

.m-50 {
  margin: 50px !important; }

.p-50 {
  padding: 50px !important; }

.mt-70 {
  margin-top: 70px !important; }

.pt-70 {
  padding-top: 70px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.ml-70 {
  margin-left: 70px !important; }

.pl-70 {
  padding-left: 70px !important; }

.mr-70 {
  margin-right: 70px !important; }

.pr-70 {
  padding-right: 70px !important; }

.mc-70 {
  margin-center: 70px !important; }

.pc-70 {
  padding-center: 70px !important; }

.m-70 {
  margin: 70px !important; }

.p-70 {
  padding: 70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.pt-75 {
  padding-top: 75px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.ml-75 {
  margin-left: 75px !important; }

.pl-75 {
  padding-left: 75px !important; }

.mr-75 {
  margin-right: 75px !important; }

.pr-75 {
  padding-right: 75px !important; }

.mc-75 {
  margin-center: 75px !important; }

.pc-75 {
  padding-center: 75px !important; }

.m-75 {
  margin: 75px !important; }

.p-75 {
  padding: 75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.pt-80 {
  padding-top: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.ml-80 {
  margin-left: 80px !important; }

.pl-80 {
  padding-left: 80px !important; }

.mr-80 {
  margin-right: 80px !important; }

.pr-80 {
  padding-right: 80px !important; }

.mc-80 {
  margin-center: 80px !important; }

.pc-80 {
  padding-center: 80px !important; }

.m-80 {
  margin: 80px !important; }

.p-80 {
  padding: 80px !important; }

.mt-100 {
  margin-top: 100px !important; }

.pt-100 {
  padding-top: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.ml-100 {
  margin-left: 100px !important; }

.pl-100 {
  padding-left: 100px !important; }

.mr-100 {
  margin-right: 100px !important; }

.pr-100 {
  padding-right: 100px !important; }

.mc-100 {
  margin-center: 100px !important; }

.pc-100 {
  padding-center: 100px !important; }

.m-100 {
  margin: 100px !important; }

.p-100 {
  padding: 100px !important; }

.text-top {
  text-align: top; }

.text-bottom {
  text-align: bottom; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.float-top {
  float: top; }

.float-bottom {
  float: bottom; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-center {
  float: center; }

.float-top {
  float: top; }

.float-bottom {
  float: bottom; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.float-center {
  float: center; }

.w-auto {
  width: auto% !important; }

.w--20 {
  width: -20% !important; }

.w--15 {
  width: -15% !important; }

.w--10 {
  width: -10% !important; }

.w--5 {
  width: -5% !important; }

.w-0 {
  width: 0% !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-50 {
  width: 50% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-100 {
  width: 100% !important; }

@font-face {
  font-family: 'FluvipIcons';
  src: url(/static/media/FluvipIcons.8f7826ea.eot);
  src: url(/static/media/FluvipIcons.8f7826ea.eot) format("embedded-opentype"), url(/static/media/FluvipIcons.ce0590ef.ttf) format("truetype"), url(/static/media/FluvipIcons.8d227cc0.woff) format("woff"), url(/static/media/FluvipIcons.36b09e20.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

button i,
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FluvipIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^='icon-'] {
  position: relative; }

.icon-exchange:before {
  content: "\e975"; }

.icon-currency:before {
  content: "\e976"; }

.icon-utils:before {
  content: "\e977"; }

.icon-shared-file:before {
  content: '\e970'; }

.icon-open-modal:before {
  content: '\e96f'; }

.icon-indicators:before {
  content: '\e96e'; }

.icon-calendar_content_pdf:before {
  content: '\e96c'; }

.icon-calendar_content:before {
  content: '\e96d'; }

.icon-camera-brief:before {
  content: '\e96b'; }

.icon-finance:before {
  content: '\e969'; }

.icon-excel:before {
  content: '\e968'; }

.icon-social-data:before {
  content: '\e967';
  margin-right: 2px; }

.icon-share:before {
  content: '\e919'; }

.icon-reel:before {
  content: '\e965'; }

.icon-facebook:before {
  content: '\e906'; }

.icon-instagram:before {
  content: '\e90a'; }

.icon-clear:before {
  content: '\e910'; }

.icon-tiktok:before {
  content: '\e911'; }

.icon-twitter:before {
  content: '\e929'; }

.icon-trash:before {
  content: '\e962'; }

.icon-on:before {
  content: '\e963'; }

.icon-off:before {
  content: '\e964'; }

.icon-video:before {
  content: '\e900'; }

.icon-story:before {
  content: '\e901'; }

.icon-burstStories:before {
  content: '\e96a'; }

.icon-bar-graph:before {
  content: '\e902'; }

.icon-briefcase:before {
  content: '\e903'; }

.icon-family:before {
  content: '\e904'; }

.icon-plus-circle:before {
  content: '\e905'; }

.icon-envelope:before {
  content: '\e907'; }

.icon-highlight:before {
  content: '\e908'; }

.icon-arrow-left:before {
  content: '\e909'; }

.icon-influencer:before {
  content: '\e90b'; }

.icon-plus:before {
  content: '\e90c'; }

.icon-crown:before {
  content: '\e90d'; }

.icon-cross-circle:before {
  content: '\e90e'; }

.icon-check-circle:before {
  content: '\e90f'; }

.icon-check:before {
  content: '\e912'; }

.icon-cross:before {
  content: '\e913'; }

.icon-block:before {
  content: '\e914'; }

.icon-gift:before {
  content: '\e915'; }

.icon-logout:before {
  content: '\e916'; }

.icon-dashboard:before {
  content: '\e917'; }

.icon-whatsapp:before {
  content: '\e918'; }

.icon-menu:before {
  content: '\e91a'; }

.icon-photo:before {
  content: '\e91b'; }

.icon-mirror:before {
  content: '\e91c'; }

.icon-carousel:before {
  content: '\e91d'; }

.icon-genders:before {
  content: '\e91e'; }

.icon-arrow-up:before {
  content: '\e91f'; }

.icon-arrow-right:before {
  content: '\e920'; }

.icon-arrow-down:before {
  content: '\e921'; }

.icon-laptop:before {
  content: '\e922'; }

.icon-money-bag:before {
  content: '\e923'; }

.icon-shining-star:before {
  content: '\e924'; }

.icon-art:before {
  content: '\e925'; }

.icon-chip:before {
  content: '\e926'; }

.icon-mask:before {
  content: '\e927'; }

.icon-plane:before {
  content: '\e928'; }

.icon-post:before {
  content: '\e92a'; }

.icon-hourglass:before {
  content: '\e92b'; }

.icon-file:before {
  content: '\e92c'; }

.icon-gear:before {
  content: '\e92d'; }

.icon-link:before {
  content: '\e92e'; }

.icon-user:before {
  content: '\e92f'; }

.icon-igtv:before {
  content: '\e930'; }

.icon-wedding-rings:before {
  content: '\e931'; }

.icon-diamond:before {
  content: '\e932'; }

.icon-book:before {
  content: '\e933'; }

.icon-bag:before {
  content: '\e934'; }

.icon-star:before {
  content: '\e935'; }

.icon-paw:before {
  content: '\e936'; }

.icon-sticker:before {
  content: '\e937'; }

.icon-click:before {
  content: '\e938'; }

.icon-expand:before {
  content: '\e939'; }

.icon-megaphone:before {
  content: '\e93a'; }

.icon-rattle:before {
  content: '\e93b'; }

.icon-bookmark:before {
  content: '\e93c'; }

.icon-martini:before {
  content: '\e93d'; }

.icon-dumbell:before {
  content: '\e93e'; }

.icon-cutlery:before {
  content: '\e93f'; }

.icon-duplicate:before {
  content: '\e940'; }

.icon-tv:before {
  content: '\e941'; }

.icon-return:before {
  content: '\e942'; }

.icon-box:before {
  content: '\e943'; }

.icon-global:before {
  content: '\e944'; }

.icon-camera:before {
  content: '\e945'; }

.icon-info:before {
  content: '\e946'; }

.icon-squares:before {
  content: '\e947'; }

.icon-search:before {
  content: '\e948'; }

.icon-star-graph:before {
  content: '\e949'; }

.icon-fanpage:before {
  content: '\e94a'; }

.icon-heart-graph:before {
  content: '\e94b'; }

.icon-line-graph:before {
  content: '\e94c'; }

.icon-eye:before {
  content: '\e94d'; }

.icon-heart:before {
  content: '\e94e'; }

.icon-like:before {
  content: '\e94f'; }

.icon-comment:before {
  content: '\e950'; }

.icon-follower:before {
  content: '\e951'; }

.icon-female:before {
  content: '\e952'; }

.icon-male:before {
  content: '\e953'; }

.icon-edit:before {
  content: '\e954'; }

.icon-cellphone:before {
  content: '\e955'; }

.icon-trash1:before {
  content: '\e956'; }

.icon-phone:before {
  content: '\e957'; }

.icon-calendar:before {
  content: '\e958'; }

.icon-pin:before {
  content: '\e959'; }

.icon-download:before {
  content: '\e95a'; }

.icon-upload:before {
  content: '\e95b'; }

.icon-bell:before {
  content: '\e95c'; }

.icon-linkedin:before {
  content: '\e95d'; }

.icon-youtube:before {
  content: '\e95e'; }

.icon-timer:before {
  content: '\e95f'; }

.icon-stopwatch:before {
  content: '\e960'; }

.icon-hashtag:before {
  content: '\e961'; }

.icon-live:before {
  content: '\e966'; }

.icon-activewear:before {
  content: '\e975'; }

.icon-agriculture:before {
  content: '\e976'; }

.icon-art-design:before {
  content: '\e977'; }

.icon-automotive:before {
  content: '\e978'; }

.icon-beauty-cosmetics:before {
  content: '\e979'; }

.icon-beer-wine-spirits:before {
  content: '\e97a'; }

.icon-business-careers:before {
  content: '\e97b'; }

.icon-camera-photography:before {
  content: '\e97c'; }

.icon-cars-motorbikes:before {
  content: '\e97d'; }

.icon-clothes-shoes-handbags-accessories:before {
  content: '\e97e'; }

.icon-coffee-tea-beverages:before {
  content: '\e97f'; }

.icon-courier:before {
  content: '\e980'; }

.icon-digital:before {
  content: '\e981'; }

.icon-drinks:before {
  content: '\e982'; }

.icon-education:before {
  content: '\e983'; }

.icon-electronics-computers:before {
  content: '\e984'; }

.icon-electronics:before {
  content: '\e985'; }

.icon-entertainment:before {
  content: '\e986'; }

.icon-events:before {
  content: '\e987'; }

.icon-financial-insurance:before {
  content: '\e988'; }

.icon-fitness-yoga:before {
  content: '\e989'; }

.icon-food:before {
  content: '\e98a'; }

.icon-friends-family-relationships:before {
  content: '\e98b'; }

.icon-gaming:before {
  content: '\e98c'; }

.icon-health-beauty:before {
  content: '\e98d'; }

.icon-healthcare-medicine:before {
  content: '\e98e'; }

.icon-healthy-lifestyle:before {
  content: '\e98f'; }

.icon-home-decor-furniture-garden:before {
  content: '\e990'; }

.icon-home-decor:before {
  content: '\e991'; }

.icon-home:before {
  content: '\e992'; }

.icon-humor:before {
  content: '\e993'; }

.icon-jewellery-watches:before {
  content: '\e994'; }

.icon-kids:before {
  content: '\e995'; }

.icon-luxury-goods:before {
  content: '\e996'; }

.icon-music:before {
  content: '\e997'; }

.icon-personal-hygiene:before {
  content: '\e998'; }

.icon-pet:before {
  content: '\e999'; }

.icon-pets:before {
  content: '\e99a'; }

.icon-professional:before {
  content: '\e99b'; }

.icon-real-state-construction:before {
  content: '\e99c'; }

.icon-restaurants-food-grocery:before {
  content: '\e99d'; }

.icon-shopping-retail:before {
  content: '\e99e'; }

.icon-sport:before {
  content: '\e99f'; }

.icon-sports:before {
  content: '\e9a0'; }

.icon-stationery:before {
  content: '\e9a1'; }

.icon-telecomunications:before {
  content: '\e9a2'; }

.icon-television-film:before {
  content: '\e9a3'; }

.icon-tobacco-smoking:before {
  content: '\e9a4'; }

.icon-toys-children-baby:before {
  content: '\e9a5'; }

.icon-travel-tourism-aviation:before {
  content: '\e9a6'; }

.icon-turism:before {
  content: '\e9a7'; }

.icon-wardrobe-accesories:before {
  content: '\e9a8'; }

.icon-wedding:before {
  content: '\e9a9'; }

.grid-block {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

.grid-block .span-sm-1 {
  grid-column: span 1; }

.grid-block .span-sm-2 {
  grid-column: span 2; }

.grid-block .span-sm-3 {
  grid-column: span 3; }

.grid-block .span-sm-4 {
  grid-column: span 4; }

.grid-block .span-sm-5 {
  grid-column: span 5; }

.grid-block .span-sm-6 {
  grid-column: span 6; }

.grid-block .span-sm-7 {
  grid-column: span 7; }

.grid-block .span-sm-8 {
  grid-column: span 8; }

.grid-block .span-sm-9 {
  grid-column: span 9; }

.grid-block .span-sm-10 {
  grid-column: span 10; }

.grid-block .span-sm-11 {
  grid-column: span 11; }

.grid-block .span-sm-12 {
  grid-column: span 12; }

@media only screen and (min-width: 481px) {
  .grid-block .span-sm-1 {
    grid-column: span 1; }
  .grid-block .span-sm-2 {
    grid-column: span 2; }
  .grid-block .span-sm-3 {
    grid-column: span 3; }
  .grid-block .span-sm-4 {
    grid-column: span 4; }
  .grid-block .span-sm-5 {
    grid-column: span 5; }
  .grid-block .span-sm-6 {
    grid-column: span 6; }
  .grid-block .span-sm-7 {
    grid-column: span 7; }
  .grid-block .span-sm-8 {
    grid-column: span 8; }
  .grid-block .span-sm-9 {
    grid-column: span 9; }
  .grid-block .span-sm-10 {
    grid-column: span 10; }
  .grid-block .span-sm-11 {
    grid-column: span 11; }
  .grid-block .span-sm-12 {
    grid-column: span 12; } }

@media only screen and (min-width: 641px) {
  .grid-block .span-md-1 {
    grid-column: span 1; }
  .grid-block .span-md-2 {
    grid-column: span 2; }
  .grid-block .span-md-3 {
    grid-column: span 3; }
  .grid-block .span-md-4 {
    grid-column: span 4; }
  .grid-block .span-md-5 {
    grid-column: span 5; }
  .grid-block .span-md-6 {
    grid-column: span 6; }
  .grid-block .span-md-7 {
    grid-column: span 7; }
  .grid-block .span-md-8 {
    grid-column: span 8; }
  .grid-block .span-md-9 {
    grid-column: span 9; }
  .grid-block .span-md-10 {
    grid-column: span 10; }
  .grid-block .span-md-11 {
    grid-column: span 11; }
  .grid-block .span-md-12 {
    grid-column: span 12; } }

@media only screen and (min-width: 1025px) {
  .grid-block .span-lg-1 {
    grid-column: span 1; }
  .grid-block .span-lg-2 {
    grid-column: span 2; }
  .grid-block .span-lg-3 {
    grid-column: span 3; }
  .grid-block .span-lg-4 {
    grid-column: span 4; }
  .grid-block .span-lg-5 {
    grid-column: span 5; }
  .grid-block .span-lg-6 {
    grid-column: span 6; }
  .grid-block .span-lg-7 {
    grid-column: span 7; }
  .grid-block .span-lg-8 {
    grid-column: span 8; }
  .grid-block .span-lg-9 {
    grid-column: span 9; }
  .grid-block .span-lg-10 {
    grid-column: span 10; }
  .grid-block .span-lg-11 {
    grid-column: span 11; }
  .grid-block .span-lg-12 {
    grid-column: span 12; } }

@media only screen and (min-width: 1600px) {
  .grid-block .span-xlg-1 {
    grid-column: span 1; }
  .grid-block .span-xlg-2 {
    grid-column: span 2; }
  .grid-block .span-xlg-3 {
    grid-column: span 3; }
  .grid-block .span-xlg-4 {
    grid-column: span 4; }
  .grid-block .span-xlg-5 {
    grid-column: span 5; }
  .grid-block .span-xlg-6 {
    grid-column: span 6; }
  .grid-block .span-xlg-7 {
    grid-column: span 7; }
  .grid-block .span-xlg-8 {
    grid-column: span 8; }
  .grid-block .span-xlg-9 {
    grid-column: span 9; }
  .grid-block .span-xlg-10 {
    grid-column: span 10; }
  .grid-block .span-xlg-11 {
    grid-column: span 11; }
  .grid-block .span-xlg-12 {
    grid-column: span 12; } }

.grid-gap-20 {
  grid-gap: 20px; }

.sna-filters .ant-card {
  background-color: #ffffff;
  margin-bottom: 25px;
  border-radius: 4px; }

.sna-filters .ant-collapse > .ant-collapse-item > .ant-collapse-header,
.sna-filters .ant-collapse-content > .ant-collapse-content-box {
  padding: 0; }

.sna-filters .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  margin-bottom: 20px; }

.sna-filters .table-filters {
  margin-bottom: 0; }

.ant-layout,
.ant-modal-root,
.ant-modal-content {
  /* Tabs */ }
  .ant-layout h1,
  .ant-layout h2,
  .ant-layout h3,
  .ant-layout h4,
  .ant-layout h5,
  .ant-layout h6,
  .ant-modal-root h1,
  .ant-modal-root h2,
  .ant-modal-root h3,
  .ant-modal-root h4,
  .ant-modal-root h5,
  .ant-modal-root h6,
  .ant-modal-content h1,
  .ant-modal-content h2,
  .ant-modal-content h3,
  .ant-modal-content h4,
  .ant-modal-content h5,
  .ant-modal-content h6 {
    font-weight: bold;
    margin: 0; }
  .ant-layout h1,
  .ant-modal-root h1,
  .ant-modal-content h1 {
    font-size: 1.875rem; }
  .ant-layout h2,
  .ant-modal-root h2,
  .ant-modal-content h2 {
    font-size: 1.25rem; }
  .ant-layout h3,
  .ant-modal-root h3,
  .ant-modal-content h3 {
    font-size: 1.125rem; }
  .ant-layout h4,
  .ant-modal-root h4,
  .ant-modal-content h4 {
    font-size: 1rem; }
  .ant-layout h5,
  .ant-modal-root h5,
  .ant-modal-content h5 {
    font-size: 0.875rem; }
  .ant-layout h6,
  .ant-modal-root h6,
  .ant-modal-content h6 {
    font-size: 0.75rem; }
  .ant-layout .ant-btn,
  .ant-modal-root .ant-btn,
  .ant-modal-content .ant-btn {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 24px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    padding: 0 30px;
    height: 45px; }
    .ant-layout .ant-btn:hover, .ant-layout .ant-btn:focus,
    .ant-modal-root .ant-btn:hover,
    .ant-modal-root .ant-btn:focus,
    .ant-modal-content .ant-btn:hover,
    .ant-modal-content .ant-btn:focus {
      border-color: #df249d; }
    .ant-layout .ant-btn-secondary, .ant-layout .ant-btn-secondary:hover, .ant-layout .ant-btn-secondary:focus, .ant-layout .ant-btn-secondary:active,
    .ant-modal-root .ant-btn-secondary,
    .ant-modal-root .ant-btn-secondary:hover,
    .ant-modal-root .ant-btn-secondary:focus,
    .ant-modal-root .ant-btn-secondary:active,
    .ant-modal-content .ant-btn-secondary,
    .ant-modal-content .ant-btn-secondary:hover,
    .ant-modal-content .ant-btn-secondary:focus,
    .ant-modal-content .ant-btn-secondary:active {
      background-color: #1442f9;
      border-color: #1442f9;
      color: #fff; }
    .ant-layout .ant-btn-ghost, .ant-layout .ant-btn-ghost:hover, .ant-layout .ant-btn-ghost:focus, .ant-layout .ant-btn-ghost:active,
    .ant-modal-root .ant-btn-ghost,
    .ant-modal-root .ant-btn-ghost:hover,
    .ant-modal-root .ant-btn-ghost:focus,
    .ant-modal-root .ant-btn-ghost:active,
    .ant-modal-content .ant-btn-ghost,
    .ant-modal-content .ant-btn-ghost:hover,
    .ant-modal-content .ant-btn-ghost:focus,
    .ant-modal-content .ant-btn-ghost:active {
      border-color: #df249d; }
    .ant-layout .ant-btn:hover,
    .ant-modal-root .ant-btn:hover,
    .ant-modal-content .ant-btn:hover {
      opacity: 0.9; }
    .ant-layout .ant-btn-sm,
    .ant-modal-root .ant-btn-sm,
    .ant-modal-content .ant-btn-sm {
      font-weight: lighter;
      font-size: 1rem;
      padding: 0 11px;
      height: 34px; }
    .ant-layout .ant-btn i + span,
    .ant-modal-root .ant-btn i + span,
    .ant-modal-content .ant-btn i + span {
      margin: 0 20px 0 0; }
    .ant-layout .ant-btn-link, .ant-layout .ant-btn-link:hover, .ant-layout .ant-btn-link:focus, .ant-layout .ant-btn-link:active,
    .ant-modal-root .ant-btn-link,
    .ant-modal-root .ant-btn-link:hover,
    .ant-modal-root .ant-btn-link:focus,
    .ant-modal-root .ant-btn-link:active,
    .ant-modal-content .ant-btn-link,
    .ant-modal-content .ant-btn-link:hover,
    .ant-modal-content .ant-btn-link:focus,
    .ant-modal-content .ant-btn-link:active {
      font-weight: lighter;
      border-radius: 0;
      font-size: 1rem;
      height: auto;
      border: none;
      padding: 0; }
    .ant-layout .ant-btn.circle-button,
    .ant-modal-root .ant-btn.circle-button,
    .ant-modal-content .ant-btn.circle-button {
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border-radius: 50%;
      display: -webkit-flex;
      display: flex;
      height: 43px;
      width: 43px;
      padding: 0; }
      .ant-layout .ant-btn.circle-button i,
      .ant-modal-root .ant-btn.circle-button i,
      .ant-modal-content .ant-btn.circle-button i {
        margin: 0 !important;
        top: 0 !important; }
    .ant-layout .ant-btn.circle-small,
    .ant-modal-root .ant-btn.circle-small,
    .ant-modal-content .ant-btn.circle-small {
      height: 30px;
      width: 30px; }
  .ant-layout p,
  .ant-modal-root p,
  .ant-modal-content p {
    font-size: 1rem;
    font-weight: normal; }
    .ant-layout p strong,
    .ant-modal-root p strong,
    .ant-modal-content p strong {
      font-weight: bold; }
  .ant-layout p.small,
  .ant-modal-root p.small,
  .ant-modal-content p.small {
    font-size: 0.875rem; }
  .ant-layout .ant-tag,
  .ant-modal-root .ant-tag,
  .ant-modal-content .ant-tag {
    font-size: 0.75rem;
    word-wrap: break-word;
    font-weight: normal;
    white-space: normal;
    text-align: center; }
  .ant-layout .ant-table-wrapper table,
  .ant-modal-root .ant-table-wrapper table,
  .ant-modal-content .ant-table-wrapper table {
    color: #000; }
    .ant-layout .ant-table-wrapper table thead th,
    .ant-modal-root .ant-table-wrapper table thead th,
    .ant-modal-content .ant-table-wrapper table thead th {
      border-bottom: 1px solid #f6f6f6;
      background-color: transparent;
      padding: 21px 6px; }
      .ant-layout .ant-table-wrapper table thead th .ant-table-column-title,
      .ant-modal-root .ant-table-wrapper table thead th .ant-table-column-title,
      .ant-modal-content .ant-table-wrapper table thead th .ant-table-column-title {
        font-size: 1rem;
        font-weight: bold; }
      .ant-layout .ant-table-wrapper table thead th.text-center span,
      .ant-modal-root .ant-table-wrapper table thead th.text-center span,
      .ant-modal-content .ant-table-wrapper table thead th.text-center span {
        text-align: center;
        width: 100%; }
    .ant-layout .ant-table-wrapper table thead .networks,
    .ant-modal-root .ant-table-wrapper table thead .networks,
    .ant-modal-content .ant-table-wrapper table thead .networks {
      text-align: center; }
    .ant-layout .ant-table-wrapper table tbody td,
    .ant-modal-root .ant-table-wrapper table tbody td,
    .ant-modal-content .ant-table-wrapper table tbody td {
      border-bottom: 1px solid #d2d2d1;
      padding: 14px 8px; }
    .ant-layout .ant-table-wrapper table tbody i,
    .ant-modal-root .ant-table-wrapper table tbody i,
    .ant-modal-content .ant-table-wrapper table tbody i {
      font-size: 24px; }
    .ant-layout .ant-table-wrapper table tbody .table-actions,
    .ant-modal-root .ant-table-wrapper table tbody .table-actions,
    .ant-modal-content .ant-table-wrapper table tbody .table-actions {
      -webkit-justify-content: center;
              justify-content: center;
      display: -webkit-flex;
      display: flex; }
      .ant-layout .ant-table-wrapper table tbody .table-actions button i,
      .ant-modal-root .ant-table-wrapper table tbody .table-actions button i,
      .ant-modal-content .ant-table-wrapper table tbody .table-actions button i {
        font-size: 30px; }
      .ant-layout .ant-table-wrapper table tbody .table-actions button + button,
      .ant-modal-root .ant-table-wrapper table tbody .table-actions button + button,
      .ant-modal-content .ant-table-wrapper table tbody .table-actions button + button {
        margin-left: 15px; }
    .ant-layout .ant-table-wrapper table tbody .ant-avatar,
    .ant-modal-root .ant-table-wrapper table tbody .ant-avatar,
    .ant-modal-content .ant-table-wrapper table tbody .ant-avatar {
      height: 57px !important;
      width: 57px !important; }
    .ant-layout .ant-table-wrapper table tbody .img-country,
    .ant-modal-root .ant-table-wrapper table tbody .img-country,
    .ant-modal-content .ant-table-wrapper table tbody .img-country {
      width: 27px; }
    .ant-layout .ant-table-wrapper table tbody .brand-logo,
    .ant-modal-root .ant-table-wrapper table tbody .brand-logo,
    .ant-modal-content .ant-table-wrapper table tbody .brand-logo {
      border-radius: 50px;
      height: 55px;
      width: 55px; }
    .ant-layout .ant-table-wrapper table tbody .avatars-list img,
    .ant-modal-root .ant-table-wrapper table tbody .avatars-list img,
    .ant-modal-content .ant-table-wrapper table tbody .avatars-list img {
      border-radius: 50%;
      height: 57px;
      width: 57px; }
      .ant-layout .ant-table-wrapper table tbody .avatars-list img:nth-child(2),
      .ant-modal-root .ant-table-wrapper table tbody .avatars-list img:nth-child(2),
      .ant-modal-content .ant-table-wrapper table tbody .avatars-list img:nth-child(2) {
        position: relative;
        left: -15px; }
    .ant-layout .ant-table-wrapper table tbody p,
    .ant-modal-root .ant-table-wrapper table tbody p,
    .ant-modal-content .ant-table-wrapper table tbody p {
      margin-bottom: 0; }
    .ant-layout .ant-table-wrapper table tbody .networks,
    .ant-modal-root .ant-table-wrapper table tbody .networks,
    .ant-modal-content .ant-table-wrapper table tbody .networks {
      text-align: center; }
      .ant-layout .ant-table-wrapper table tbody .networks i,
      .ant-modal-root .ant-table-wrapper table tbody .networks i,
      .ant-modal-content .ant-table-wrapper table tbody .networks i {
        margin-right: 5px;
        font-size: 18px; }
        .ant-layout .ant-table-wrapper table tbody .networks i:last-of-type,
        .ant-modal-root .ant-table-wrapper table tbody .networks i:last-of-type,
        .ant-modal-content .ant-table-wrapper table tbody .networks i:last-of-type {
          margin: 0; }
    .ant-layout .ant-table-wrapper table tbody .dropdown-container,
    .ant-modal-root .ant-table-wrapper table tbody .dropdown-container,
    .ant-modal-content .ant-table-wrapper table tbody .dropdown-container {
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      -webkit-align-items: center;
              align-items: center;
      display: -webkit-flex;
      display: flex; }
      .ant-layout .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg,
      .ant-modal-root .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg,
      .ant-modal-content .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg {
        width: auto;
        height: auto; }
        .ant-layout .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg i,
        .ant-modal-root .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg i,
        .ant-modal-content .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg i {
          font-size: 20px;
          margin-top: 10px;
          color: #747474; }
        .ant-layout .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg:hover i,
        .ant-modal-root .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg:hover i,
        .ant-modal-content .ant-table-wrapper table tbody .dropdown-container .ant-btn-icon-only.ant-btn-lg:hover i {
          color: #1442f9; }
      .ant-layout .ant-table-wrapper table tbody .dropdown-container .dropdown-options,
      .ant-modal-root .ant-table-wrapper table tbody .dropdown-container .dropdown-options,
      .ant-modal-content .ant-table-wrapper table tbody .dropdown-container .dropdown-options {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        margin: 0px 5px 0 10px; }
    .ant-layout .ant-table-wrapper table tbody i.anticon-right,
    .ant-modal-root .ant-table-wrapper table tbody i.anticon-right,
    .ant-modal-content .ant-table-wrapper table tbody i.anticon-right {
      font-size: 16px;
      cursor: pointer; }
  .ant-layout .table-pagination,
  .ant-modal-root .table-pagination,
  .ant-modal-content .table-pagination {
    padding: 30px 0;
    margin: 0; }
    .ant-layout .table-pagination .ant-pagination,
    .ant-modal-root .table-pagination .ant-pagination,
    .ant-modal-content .table-pagination .ant-pagination {
      text-align: right; }
      .ant-layout .table-pagination .ant-pagination .ant-pagination-item,
      .ant-modal-root .table-pagination .ant-pagination .ant-pagination-item,
      .ant-modal-content .table-pagination .ant-pagination .ant-pagination-item {
        border-color: #1442f9; }
        .ant-layout .table-pagination .ant-pagination .ant-pagination-item a,
        .ant-modal-root .table-pagination .ant-pagination .ant-pagination-item a,
        .ant-modal-content .table-pagination .ant-pagination .ant-pagination-item a {
          color: #1442f9; }
      .ant-layout .table-pagination .ant-pagination .ant-select-selection,
      .ant-modal-root .table-pagination .ant-pagination .ant-select-selection,
      .ant-modal-content .table-pagination .ant-pagination .ant-select-selection {
        padding-left: 10px;
        padding-right: 30px;
        padding-top: 1px; }
        .ant-layout .table-pagination .ant-pagination .ant-select-selection:hover,
        .ant-modal-root .table-pagination .ant-pagination .ant-select-selection:hover,
        .ant-modal-content .table-pagination .ant-pagination .ant-select-selection:hover {
          border-color: #1578d5; }
  .ant-layout .ant-table-tbody
> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
> td,
  .ant-modal-root .ant-table-tbody
> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
> td,
  .ant-modal-content .ant-table-tbody
> tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
> td {
    background: transparent; }
  .ant-layout .ant-table-body,
  .ant-modal-root .ant-table-body,
  .ant-modal-content .ant-table-body {
    overflow-x: scroll; }
  .ant-layout .ant-tabs,
  .ant-modal-root .ant-tabs,
  .ant-modal-content .ant-tabs {
    border-bottom: 2px solid #eeeeee;
    margin-bottom: 53px; }
    .ant-layout .ant-tabs .ant-tabs-nav,
    .ant-modal-root .ant-tabs .ant-tabs-nav,
    .ant-modal-content .ant-tabs .ant-tabs-nav {
      width: 100%; }
      .ant-layout .ant-tabs .ant-tabs-nav > div,
      .ant-modal-root .ant-tabs .ant-tabs-nav > div,
      .ant-modal-content .ant-tabs .ant-tabs-nav > div {
        grid-template-columns: repeat(3, 1fr);
        -webkit-align-items: center;
                align-items: center;
        display: grid; }
    .ant-layout .ant-tabs .ant-tabs-bar,
    .ant-modal-root .ant-tabs .ant-tabs-bar,
    .ant-modal-content .ant-tabs .ant-tabs-bar {
      text-align: center;
      border: none;
      margin: 0; }
      .ant-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab,
      .ant-modal-root .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab,
      .ant-modal-content .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
        color: rgba(166, 165, 163, 0.5);
        font-size: 1.125rem;
        padding: 0 0 15px 0;
        font-weight: 300;
        margin: 0; }
      .ant-layout .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active,
      .ant-modal-root .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active,
      .ant-modal-content .ant-tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: bold;
        color: #000; }
    .ant-layout .ant-tabs .ant-tabs-ink-bar,
    .ant-modal-root .ant-tabs .ant-tabs-ink-bar,
    .ant-modal-content .ant-tabs .ant-tabs-ink-bar {
      height: 4px;
      bottom: 2px; }
  .ant-layout .ant-form-item .float-label-container .label,
  .ant-modal-root .ant-form-item .float-label-container .label,
  .ant-modal-content .ant-form-item .float-label-container .label {
    color: #a6a5a3;
    left: 0;
    bottom: 10px;
    top: auto; }
    .ant-layout .ant-form-item .float-label-container .label.label-float,
    .ant-modal-root .ant-form-item .float-label-container .label.label-float,
    .ant-modal-content .ant-form-item .float-label-container .label.label-float {
      font-weight: bold;
      top: -8px; }
  .ant-layout .ant-form-item .input-label,
  .ant-modal-root .ant-form-item .input-label,
  .ant-modal-content .ant-form-item .input-label {
    font-size: 0.75rem;
    color: #1442f9;
    font-weight: bold; }
  .ant-layout .ant-form-item .ant-input-group-addon,
  .ant-modal-root .ant-form-item .ant-input-group-addon,
  .ant-modal-content .ant-form-item .ant-input-group-addon {
    background-color: transparent;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    padding: 0;
    border-radius: 0;
    margin: 0;
    position: absolute;
    top: 8px;
    z-index: 1; }
  .ant-layout .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group-addon,
  .ant-modal-root .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group-addon,
  .ant-modal-content .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group-addon {
    right: 20px; }
  .ant-layout .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group input,
  .ant-modal-root .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group input,
  .ant-modal-content .ant-form-item .addon-after.ant-input-group-wrapper .ant-input-group input {
    padding-right: 30px; }
  .ant-layout .ant-form-item .ant-input,
  .ant-layout .ant-form-item .ant-input-number,
  .ant-modal-root .ant-form-item .ant-input,
  .ant-modal-root .ant-form-item .ant-input-number,
  .ant-modal-content .ant-form-item .ant-input,
  .ant-modal-content .ant-form-item .ant-input-number {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #a6a5a3;
    background-color: transparent;
    font-size: 0.9375rem;
    box-shadow: none;
    outline: none;
    height: 36px;
    padding: 0; }
    .ant-layout .ant-form-item .ant-input:hover, .ant-layout .ant-form-item .ant-input:focus,
    .ant-layout .ant-form-item .ant-input-number:hover,
    .ant-layout .ant-form-item .ant-input-number:focus,
    .ant-modal-root .ant-form-item .ant-input:hover,
    .ant-modal-root .ant-form-item .ant-input:focus,
    .ant-modal-root .ant-form-item .ant-input-number:hover,
    .ant-modal-root .ant-form-item .ant-input-number:focus,
    .ant-modal-content .ant-form-item .ant-input:hover,
    .ant-modal-content .ant-form-item .ant-input:focus,
    .ant-modal-content .ant-form-item .ant-input-number:hover,
    .ant-modal-content .ant-form-item .ant-input-number:focus {
      border-color: #1442f9;
      box-shadow: none; }
  .ant-layout .ant-form-item .ant-input-number .ant-input-number-input,
  .ant-modal-root .ant-form-item .ant-input-number .ant-input-number-input,
  .ant-modal-content .ant-form-item .ant-input-number .ant-input-number-input {
    padding: 0; }
  .ant-layout .ant-form-item .ant-select-selection,
  .ant-modal-root .ant-form-item .ant-select-selection,
  .ant-modal-content .ant-form-item .ant-select-selection {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #a6a5a3;
    background-color: transparent;
    font-size: 0.9375rem;
    box-shadow: none;
    outline: none;
    height: 36px;
    padding: 0; }
    .ant-layout .ant-form-item .ant-select-selection:hover, .ant-layout .ant-form-item .ant-select-selection:focus,
    .ant-modal-root .ant-form-item .ant-select-selection:hover,
    .ant-modal-root .ant-form-item .ant-select-selection:focus,
    .ant-modal-content .ant-form-item .ant-select-selection:hover,
    .ant-modal-content .ant-form-item .ant-select-selection:focus {
      border-color: #1442f9;
      box-shadow: none; }
    .ant-layout .ant-form-item .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon,
    .ant-modal-root .ant-form-item .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon,
    .ant-modal-content .ant-form-item .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon {
      color: #a6a5a3; }
    .ant-layout .ant-form-item .ant-select-selection .ant-select-selection__rendered,
    .ant-modal-root .ant-form-item .ant-select-selection .ant-select-selection__rendered,
    .ant-modal-content .ant-form-item .ant-select-selection .ant-select-selection__rendered {
      margin: 0; }
  .ant-layout .ant-form-item .ant-select-selection-selected-value,
  .ant-modal-root .ant-form-item .ant-select-selection-selected-value,
  .ant-modal-content .ant-form-item .ant-select-selection-selected-value {
    max-width: 90%; }
  .ant-layout .ant-form-item .ant-select,
  .ant-modal-root .ant-form-item .ant-select,
  .ant-modal-content .ant-form-item .ant-select {
    width: 100%; }
    .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple,
    .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple,
    .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple {
      height: auto; }
      .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered,
      .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered,
      .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered {
        height: 36px;
        margin: 0; }
      .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice,
      .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice,
      .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice {
        border: 1px solid;
        border-radius: 2px; }
        .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__remove .anticon,
        .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__remove .anticon,
        .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__remove .anticon {
          color: #fff; }
    .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple > ul > li,
    .ant-layout .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li,
    .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple > ul > li,
    .ant-modal-root .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li,
    .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple > ul > li,
    .ant-modal-content .ant-form-item .ant-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      margin-top: 6px;
      height: 23px; }
  .ant-layout .ant-form-item.text-area,
  .ant-modal-root .ant-form-item.text-area,
  .ant-modal-content .ant-form-item.text-area {
    min-height: 84px;
    margin: 20px 0 30px 0; }
    .ant-layout .ant-form-item.text-area textarea,
    .ant-modal-root .ant-form-item.text-area textarea,
    .ant-modal-content .ant-form-item.text-area textarea {
      font-size: 0.75rem;
      border: 1px solid;
      border-radius: 5px;
      padding: 15px 10px;
      height: 84px;
      resize: none; }
    .ant-layout .ant-form-item.text-area .has-error textarea,
    .ant-modal-root .ant-form-item.text-area .has-error textarea,
    .ant-modal-content .ant-form-item.text-area .has-error textarea {
      border: solid 1px #E1198E; }
    .ant-layout .ant-form-item.text-area .input-label,
    .ant-modal-root .ant-form-item.text-area .input-label,
    .ant-modal-content .ant-form-item.text-area .input-label {
      font-size: 0.75rem;
      font-weight: bold;
      top: -25px; }
    .ant-layout .ant-form-item.text-area .label.label-float,
    .ant-modal-root .ant-form-item.text-area .label.label-float,
    .ant-modal-content .ant-form-item.text-area .label.label-float {
      top: -25px; }
  .ant-layout .ant-form-item .has-error .ant-input,
  .ant-layout .ant-form-item .has-error .ant-select-selection,
  .ant-modal-root .ant-form-item .has-error .ant-input,
  .ant-modal-root .ant-form-item .has-error .ant-select-selection,
  .ant-modal-content .ant-form-item .has-error .ant-input,
  .ant-modal-content .ant-form-item .has-error .ant-select-selection {
    border-color: #E1198E; }
  .ant-layout .ant-form-item .has-error .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon,
  .ant-modal-root .ant-form-item .has-error .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon,
  .ant-modal-content .ant-form-item .has-error .ant-select-selection i.anticon.anticon-down.ant-select-arrow-icon {
    color: #E1198E; }
  .ant-layout .ant-form-item .has-error .ant-form-explain,
  .ant-layout .ant-form-item .has-error .ant-form-split,
  .ant-modal-root .ant-form-item .has-error .ant-form-explain,
  .ant-modal-root .ant-form-item .has-error .ant-form-split,
  .ant-modal-content .ant-form-item .has-error .ant-form-explain,
  .ant-modal-content .ant-form-item .has-error .ant-form-split {
    text-align: left;
    color: #E1198E; }
  .ant-layout .ant-form-item .ant-form-explain,
  .ant-modal-root .ant-form-item .ant-form-explain,
  .ant-modal-content .ant-form-item .ant-form-explain {
    font-size: 0.75rem;
    margin-top: 2px;
    text-align: left; }
  .ant-layout .ant-form-item .ant-input,
  .ant-layout .ant-form-item .has-error .ant-input:hover,
  .ant-modal-root .ant-form-item .ant-input,
  .ant-modal-root .ant-form-item .has-error .ant-input:hover,
  .ant-modal-content .ant-form-item .ant-input,
  .ant-modal-content .ant-form-item .has-error .ant-input:hover {
    background-color: transparent; }
  .ant-layout .ant-switch,
  .ant-modal-root .ant-switch,
  .ant-modal-content .ant-switch {
    background-color: #ededed;
    border: solid 0.5px #d8d8d8; }
    .ant-layout .ant-switch::after,
    .ant-modal-root .ant-switch::after,
    .ant-modal-content .ant-switch::after {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5); }
  .ant-layout .field-with-add-button,
  .ant-modal-root .field-with-add-button,
  .ant-modal-content .field-with-add-button {
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    display: -webkit-flex;
    display: flex; }
    .ant-layout .field-with-add-button > div,
    .ant-modal-root .field-with-add-button > div,
    .ant-modal-content .field-with-add-button > div {
      width: 90%; }
    .ant-layout .field-with-add-button .add-button,
    .ant-modal-root .field-with-add-button .add-button,
    .ant-modal-content .field-with-add-button .add-button {
      border-radius: 50%;
      padding: 10px; }
      .ant-layout .field-with-add-button .add-button.disabled-button,
      .ant-modal-root .field-with-add-button .add-button.disabled-button,
      .ant-modal-content .field-with-add-button .add-button.disabled-button {
        opacity: 0.5; }
      .ant-layout .field-with-add-button .add-button:hover,
      .ant-modal-root .field-with-add-button .add-button:hover,
      .ant-modal-content .field-with-add-button .add-button:hover {
        background-color: #E1198E;
        color: #fff; }
      .ant-layout .field-with-add-button .add-button i,
      .ant-modal-root .field-with-add-button .add-button i,
      .ant-modal-content .field-with-add-button .add-button i {
        font-size: 11px; }
  .ant-layout .label-title,
  .ant-modal-root .label-title,
  .ant-modal-content .label-title {
    font-size: 0.75rem;
    font-weight: bold; }
  .ant-layout .ant-calendar-picker,
  .ant-modal-root .ant-calendar-picker,
  .ant-modal-content .ant-calendar-picker {
    margin-top: 10px;
    width: 100%; }
  .ant-layout .ant-form-item-with-help,
  .ant-modal-root .ant-form-item-with-help,
  .ant-modal-content .ant-form-item-with-help {
    margin-bottom: 10px; }
  .ant-layout .ant-form-item-control,
  .ant-modal-root .ant-form-item-control,
  .ant-modal-content .ant-form-item-control {
    line-height: 1; }
  .ant-layout .profile-photo-container .profile-photo-input,
  .ant-modal-root .profile-photo-container .profile-photo-input,
  .ant-modal-content .profile-photo-container .profile-photo-input {
    height: 180px; }
  .ant-layout .profile-photo-container .file-input,
  .ant-modal-root .profile-photo-container .file-input,
  .ant-modal-content .profile-photo-container .file-input {
    width: 180px;
    height: 180px; }
    .ant-layout .profile-photo-container .file-input .ant-upload.ant-upload-drag,
    .ant-modal-root .profile-photo-container .file-input .ant-upload.ant-upload-drag,
    .ant-modal-content .profile-photo-container .file-input .ant-upload.ant-upload-drag {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      border: 2px solid #fff; }
      .ant-layout .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:before,
      .ant-modal-root .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:before,
      .ant-modal-content .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        width: 180px;
        height: 180px;
        border: solid transparent 2px;
        border-right: solid 2px;
        -webkit-transform: rotate(230deg);
                transform: rotate(230deg);
        left: -10px;
        top: -10px; }
      .ant-layout .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:after,
      .ant-modal-root .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:after,
      .ant-modal-content .profile-photo-container .file-input .ant-upload.ant-upload-drag .ant-upload-btn:after {
        position: absolute;
        content: '';
        width: 180px;
        height: 180px;
        border: solid transparent 10px;
        border-right: solid 10px;
        border-radius: 50%;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        right: -6px;
        z-index: 101; }
    .ant-layout .profile-photo-container .file-input .ant-upload-drag-container:before,
    .ant-modal-root .profile-photo-container .file-input .ant-upload-drag-container:before,
    .ant-modal-content .profile-photo-container .file-input .ant-upload-drag-container:before {
      background-image: url(/static/media/border.96196dc3.svg);
      background-position: 0px -116px;
      position: absolute;
      height: 69px;
      width: 180px;
      bottom: 0;
      content: '';
      z-index: 100;
      left: -3px; }
    .ant-layout .profile-photo-container .file-input .image-container,
    .ant-modal-root .profile-photo-container .file-input .image-container,
    .ant-modal-content .profile-photo-container .file-input .image-container {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0; }
      .ant-layout .profile-photo-container .file-input .image-container img,
      .ant-modal-root .profile-photo-container .file-input .image-container img,
      .ant-modal-content .profile-photo-container .file-input .image-container img {
        border-radius: 50%;
        height: 176px;
        width: 176px;
        z-index: 99;
        position: relative; }
    .ant-layout .profile-photo-container .file-input .text-preview,
    .ant-modal-root .profile-photo-container .file-input .text-preview,
    .ant-modal-content .profile-photo-container .file-input .text-preview {
      display: none; }
  .ant-layout .profile-photo-container .input-label,
  .ant-modal-root .profile-photo-container .input-label,
  .ant-modal-content .profile-photo-container .input-label {
    position: relative !important;
    top: -28px !important;
    width: 180px;
    left: 0;
    text-align: center;
    color: white !important;
    font-size: 9px;
    z-index: 102; }
  .ant-layout .ant-modal-body .title-content h1,
  .ant-modal-root .ant-modal-body .title-content h1,
  .ant-modal-content .ant-modal-body .title-content h1 {
    font-size: 20px; }
  .ant-layout .ant-modal-body .title-container,
  .ant-modal-root .ant-modal-body .title-container,
  .ant-modal-content .ant-modal-body .title-container {
    margin-bottom: 45px; }
  .ant-layout .ant-modal-body .ant-card-head,
  .ant-modal-root .ant-modal-body .ant-card-head,
  .ant-modal-content .ant-modal-body .ant-card-head {
    padding: 46px 36px 0px !important; }
  .ant-layout .ant-modal-body .ant-card-body,
  .ant-modal-root .ant-modal-body .ant-card-body,
  .ant-modal-content .ant-modal-body .ant-card-body {
    padding: 0px 36px 31px !important; }
  .ant-layout .ant-modal-body .modal-footer .ant-btn,
  .ant-modal-root .ant-modal-body .modal-footer .ant-btn,
  .ant-modal-content .ant-modal-body .modal-footer .ant-btn {
    margin: 0 auto; }
  .ant-layout *,
  .ant-modal-root *,
  .ant-modal-content * {
    box-sizing: border-box; }
  .ant-layout html,
  .ant-layout body,
  .ant-layout .ant-layout,
  .ant-modal-root html,
  .ant-modal-root body,
  .ant-modal-root .ant-layout,
  .ant-modal-content html,
  .ant-modal-content body,
  .ant-modal-content .ant-layout {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    color: #000;
    margin: 0; }

label {
  display: block; }

input:focus {
  outline: none; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

a {
  text-decoration: none;
  color: #000; }

img {
  max-width: 100%; }

.capitalize {
  text-transform: capitalize; }

.avatar {
  border-radius: 50%;
  width: 30px;
  border: 1px solid #d8d8d8; }

code {
  background-color: #f1f1f1;
  margin: 15px 0;
  display: inline-block;
  padding: 10px; }

pre {
  overflow: hidden; }

strong {
  font-weight: bold; }

.sna-avatar {
  position: relative;
  text-align: center;
  margin-right: 30px;
  width: 122px;
  margin-bottom: 16px; }
  .sna-avatar img {
    border-radius: 50%;
    width: 100%; }

.avatar-decor:after, .avatar-decor:before {
  position: absolute;
  content: '';
  border-radius: 50%;
  width: 122px;
  height: 122px; }

.avatar-decor:after {
  border: solid transparent 8px;
  border-right: solid 8px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  bottom: -3px;
  right: -3px; }

.avatar-decor:before {
  border: solid transparent 2px;
  border-right: solid 2px;
  -webkit-transform: rotate(230deg);
          transform: rotate(230deg);
  left: -10px;
  top: -10px; }

.highlight-text {
  font-weight: bold; }

.pre-wrap {
  white-space: pre-wrap; }

/**  =====================
  Spinning
==========================  **/
.loading-container {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }
  .loading-container .logo {
    width: 200px;
    height: 59px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/logo-horizontal.7a31113c.svg);
    margin: auto; }

.image-loader {
  position: relative;
  height: 80px;
  width: 200px;
  margin: 200px auto auto; }

.image-loader img {
  width: 200px; }

.image-loader .lds-grid {
  margin-top: 20px; }

.loader-text {
  text-align: center;
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  font-family: Ubuntu; }

#circle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 150px;
  height: 150px; }

.loader {
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 8px transparent;
  border-top: 8px solid #1442f9;
  border-radius: 50%;
  -webkit-animation: rotate 5s linear infinite;
          animation: rotate 5s linear infinite; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/**  =====================
  Grid
==========================  **/
.lds-grid {
  position: relative;
  height: 80px;
  width: 80px;
  margin: 200px auto auto; }

.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: lds-grid 1.2s linear infinite;
          animation: lds-grid 1.2s linear infinite; }

.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }

.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }

.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s; }

.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s; }

@-webkit-keyframes lds-grid {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; } }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 58px; }
  .header a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .header a .img-logo {
      width: 110px;
      height: 30px;
      background-image: url(/static/media/logo-horizontal.7a31113c.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto; }
    .header a .img-alpina {
      width: 70px; }

.logged .header {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0; }

.ant-layout {
  background-color: #fff; }
  .ant-layout.logged .ant-layout-content {
    margin-top: 58px;
    padding: 0; }

@media only screen and (min-width: 992px) {
  .ant-layout-sider + .ant-layout {
    margin-left: 113px; }
    .ant-layout-sider + .ant-layout .ant-layout-content {
      padding: 0 50px; }
    .ant-layout-sider + .ant-layout .to-back-bar {
      margin: 0 -50px 50px; } }

@media only screen and (min-width: 992px) {
  .open + .ant-layout {
    margin-left: 266px; }
    .open + .ant-layout .ant-layout-content {
      padding: 0 70px; }
    .open + .ant-layout .to-back-bar {
      margin: 0 -70px 50px; } }

section.clear-spaces section.ant-layout main.ant-layout-content {
  margin-top: 0;
  padding: 0; }

section.clear-spaces header.ant-layout-header {
  display: none; }

.header-no-visible .ant-layout-content {
  margin-top: 0px !important; }

.ant-layout-sider {
  background-color: #f6f6f6;
  position: fixed;
  height: 100vh; }
  .ant-layout-sider.ant-layout-sider-zero-width .logout,
  .ant-layout-sider.ant-layout-sider-zero-width .config {
    display: none; }
  .ant-layout-sider .menu-header {
    height: 73px;
    border-right: solid 1px #f6f6f6;
    border-bottom: 1px solid #dcdcdc;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: #fff;
    padding: 25px 16px 25px;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 58px;
    display: -webkit-flex;
    display: flex; }
    .ant-layout-sider .menu-header h2 {
      color: #1442f9;
      line-height: 1.1; }
    .ant-layout-sider .menu-header .anticon-menu {
      display: none; }
  .ant-layout-sider .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-layout-sider .ant-menu-submenu {
    background-color: #f6f6f6;
    box-shadow: none; }
  .ant-layout-sider .ant-menu {
    background-color: #f6f6f6;
    padding: 31px 25px 0; }
    .ant-layout-sider .ant-menu .ant-menu-submenu,
    .ant-layout-sider .ant-menu .ant-menu-item {
      border-bottom: 1px solid #a6a5a3;
      padding: 0 !important;
      color: #a6a5a3;
      display: -webkit-flex;
      display: flex;
      min-height: 65px;
      margin: 0; }
      .ant-layout-sider .ant-menu .ant-menu-submenu i,
      .ant-layout-sider .ant-menu .ant-menu-item i {
        margin-right: 10px;
        font-size: 20px; }
      .ant-layout-sider .ant-menu .ant-menu-submenu:hover, .ant-layout-sider .ant-menu .ant-menu-submenu.ant-menu-item-selected,
      .ant-layout-sider .ant-menu .ant-menu-item:hover,
      .ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
        background-color: transparent;
        color: #000; }
      .ant-layout-sider .ant-menu .ant-menu-submenu.ant-menu-submenu-item-selected,
      .ant-layout-sider .ant-menu .ant-menu-item.ant-menu-submenu-item-selected {
        background-color: transparent;
        color: #000; }
      .ant-layout-sider .ant-menu .ant-menu-submenu.ant-menu-item-selected,
      .ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-selected {
        border-bottom: 5px solid;
        font-weight: bold; }
      .ant-layout-sider .ant-menu .ant-menu-submenu.logout,
      .ant-layout-sider .ant-menu .ant-menu-item.logout {
        bottom: 0; }
      .ant-layout-sider .ant-menu .ant-menu-submenu.config,
      .ant-layout-sider .ant-menu .ant-menu-item.config {
        bottom: 59px; }
      .ant-layout-sider .ant-menu .ant-menu-submenu.logout, .ant-layout-sider .ant-menu .ant-menu-submenu.config,
      .ant-layout-sider .ant-menu .ant-menu-item.logout,
      .ant-layout-sider .ant-menu .ant-menu-item.config {
        padding: 0 25px !important;
        border-bottom: none;
        position: absolute;
        min-width: 100%; }
      .ant-layout-sider .ant-menu .ant-menu-submenu span.menu-title,
      .ant-layout-sider .ant-menu .ant-menu-item span.menu-title {
        -webkit-align-items: center;
                align-items: center;
        display: -webkit-flex;
        display: flex;
        width: 100%; }
    .ant-layout-sider .ant-menu .ant-menu-submenu {
      padding-top: 10px !important;
      padding-left: 0;
      display: block; }
    .ant-layout-sider .ant-menu .ant-menu-submenu-arrow::before {
      background: #000 !important;
      color: #000 !important; }
    .ant-layout-sider .ant-menu .ant-menu-submenu-arrow::after {
      background: #000 !important;
      color: #000 !important; }
  .ant-layout-sider .ant-menu-sub {
    padding: 5px 0;
    padding-left: 10px; }
  .ant-layout-sider .ant-menu-submenu-title {
    padding: 0 !important; }
    .ant-layout-sider .ant-menu-submenu-title :hover {
      background-color: transparent;
      color: #000; }
  .ant-layout-sider .ant-menu-item:last-child {
    border: none; }

.ant-menu-inline-collapsed {
  width: 100%; }
  .ant-menu-inline-collapsed span.menu-title {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center; }
  .ant-menu-inline-collapsed .menu-text {
    display: none; }

.ant-layout-sider-collapsed .menu-header {
  -webkit-justify-content: center;
          justify-content: center; }
  .ant-layout-sider-collapsed .menu-header .anticon-menu {
    display: block; }
  .ant-layout-sider-collapsed .menu-header h2,
  .ant-layout-sider-collapsed .menu-header .anticon-close {
    display: none; }

.ant-layout-sider-collapsed .ant-menu-submenu i,
.ant-layout-sider-collapsed .ant-menu-item i {
  margin: 0 !important; }

.ant-layout-sider-collapsed .ant-menu-submenu i {
  padding-top: 10px !important; }

.theme-tester {
  position: fixed;
  right: 10px;
  z-index: 10000;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .theme-tester-select {
    width: 100%; }

.ant-form-item-control .file-input {
  height: 75px; }
  .ant-form-item-control .file-input .ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 0; }
  .ant-form-item-control .file-input .ant-upload.ant-upload-drag.small {
    background-color: #00255b;
    border: none;
    height: 40px; }
    .ant-form-item-control .file-input .ant-upload.ant-upload-drag.small .ant-upload-drag-container {
      color: #fff; }

.ant-form-item-control.has-error .file-input .ant-upload-list-item {
  margin-top: 15px; }

.ant-layout .float-label-container,
.ant-modal-root .float-label-container,
.ant-modal-content .float-label-container {
  position: relative;
  margin-bottom: 0; }
  .ant-layout .float-label-container .label,
  .ant-modal-root .float-label-container .label,
  .ant-modal-content .float-label-container .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 6px;
    top: 0;
    transition: 0.2s ease all;
    color: #979797; }
  .ant-layout .float-label-container .label-float,
  .ant-modal-root .float-label-container .label-float,
  .ant-modal-content .float-label-container .label-float {
    left: 4px;
    top: -18px;
    font-size: 12px; }
  .ant-layout .float-label-container .ant-radio-group.ant-radio-group-outline ~ label.input-label,
  .ant-modal-root .float-label-container .ant-radio-group.ant-radio-group-outline ~ label.input-label,
  .ant-modal-content .float-label-container .ant-radio-group.ant-radio-group-outline ~ label.input-label {
    top: -30px; }

.no-error-icon .ant-form-item-children-icon {
  display: none;
}

.otp-container {
  -webkit-justify-content: center;
          justify-content: center; }
  .otp-container .otp-input {
    width: 40px !important;
    margin: 5px;
    border: none;
    border-bottom: 1px solid #1442f9; }

.login {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .login .line-section {
    height: 14px;
    width: 91px;
    background: #ffffff;
    position: absolute; }
  .login .line-separator-container {
    margin: 50px 0;
    margin-bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative; }
  .login .line-separator {
    height: 1px;
    width: 100%;
    background: #ffffff; }
  .login h1 {
    font-family: Ubuntu;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
  .login .ant-form-item {
    margin-top: 40px; }
    .login .ant-form-item .anticon {
      color: #ffffff !important; }
    .login .ant-form-item label,
    .login .ant-form-item input {
      color: #ffffff !important;
      border-color: #ffffff !important;
      background-color: transparent !important; }
    .login .ant-form-item .has-error input {
      border-color: #e1198e !important; }
    .login .ant-form-item .has-error .ant-form-explain {
      color: #ffffff !important; }
  .login .forgot-password-button span {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    width: 100%;
    text-align: right; }
  .login .send-container {
    margin-top: 50px; }
  .login .version {
    text-align: right;
    color: white; }

.__theme_selected_greensocial .login {
  position: relative;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: auto;
  min-height: 900px !important; }
  .__theme_selected_greensocial .login::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(/static/media/background_wb.90a9abd5.jpg) !important;
    background-size: cover;
    background-repeat: no-repeat; }
  .__theme_selected_greensocial .login::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(/static/media/logo-dsociaks.66d994e1.png) !important;
    background-size: contain;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center; }
  .__theme_selected_greensocial .login .loginR {
    z-index: 1;
    background-color: #ffffff;
    margin: auto;
    position: absolute;
    border-radius: 2%;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 0px 12px RGB(255 255 255/38%);
    width: 22vw;
    top: 50vh; }
  .__theme_selected_greensocial .login .title-dss::before {
    content: ' The platform for \A Influencer Marketing';
    white-space: pre-wrap; }
  .__theme_selected_greensocial .login .title-dss {
    position: absolute;
    z-index: 1;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    margin-left: 0 !important;
    margin-right: 0 !important;
    top: 14vh; }
  .__theme_selected_greensocial .login .title-login {
    color: #000000;
    font-size: 30px;
    padding: 25px 0;
    text-align: center; }
  .__theme_selected_greensocial .login #col-form {
    width: 90% !important;
    margin-top: -10%; }
  .__theme_selected_greensocial .login .ant-form-item {
    margin-top: 10px; }
    .__theme_selected_greensocial .login .ant-form-item label,
    .__theme_selected_greensocial .login .ant-form-item input {
      color: #015cfe !important;
      border-color: #000000 !important;
      background-color: transparent !important;
      font-family: Ubuntu; }
    .__theme_selected_greensocial .login .ant-form-item label {
      top: -18px !important; }
    .__theme_selected_greensocial .login .ant-form-item .has-error .ant-form-explain {
      color: #ffffff !important; }
  .__theme_selected_greensocial .login .forgot-password-button span {
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #015cfe;
    width: 100%;
    text-align: center; }
  .__theme_selected_greensocial .login .send-container {
    margin-top: 10px; }
  .__theme_selected_greensocial .login #col-login {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .__theme_selected_greensocial .login #btn-login {
    border-radius: 7%;
    width: 150px;
    height: 55px;
    font-size: 20px;
    background-color: #2cf5d5 !important;
    border: #2cf5d5;
    color: #04006c !important; }

@media only screen and (max-width: 1024px) {
  .__theme_selected_greensocial .loginR {
    width: 50vw !important;
    top: 45vh !important; }
  .__theme_selected_greensocial .title-dss {
    font-size: 1.5rem !important;
    top: 22vh !important; } }

@media only screen and (max-width: 700px) {
  .__theme_selected_greensocial .login::after {
    background-size: 800px; }
  .__theme_selected_greensocial .loginR {
    width: 70vw !important;
    top: 400px !important; }
  .__theme_selected_greensocial .title-login {
    font-size: 25px !important; }
  .__theme_selected_greensocial .title-dss {
    font-size: 1.3rem !important;
    top: 150px !important; } }

.__theme_selected_atv #btn-login {
  color: #ffffff !important; }

