@import '../../../resources/styles/variables';

// =============================================================================
// Login
// =============================================================================

.login {
  height: 100vh;
  display: flex;
  align-items: center;

  .line-section {
    height: 14px;
    width: 91px;
    background: #ffffff;
    position: absolute;
  }
  .line-separator-container {
    margin: 50px 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: relative;
  }
  .line-separator {
    height: 1px;
    width: 100%;
    background: #ffffff;
  }
  h1 {
    font-family: Ubuntu;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  .ant-form-item {
    margin-top: 40px;
    .anticon {
      color: #ffffff !important;
    }
    label,
    input {
      color: #ffffff !important;
      border-color: #ffffff !important;
      background-color: transparent !important;
    }
    .has-error {
      input {
        border-color: #e1198e !important ;
      }
      .ant-form-explain {
        color: #ffffff !important;
      }
    }
  }
  .forgot-password-button {
    span {
      font-family: Ubuntu;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      width: 100%;
      text-align: right;
    }
  }
  .send-container {
    margin-top: 50px;
  }
  .version{
    text-align: right;
    color: white;
  }
}
.__theme_selected_greensocial {
  .login {
    position: relative;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    min-height: 900px !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url(../../../resources/images/background_wb.jpg) !important;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url(../../../resources/images/logo-dsociaks.png) !important;
      background-size: contain;
      background-repeat: no-repeat, no-repeat, no-repeat;
      background-position: center;
    }
    .loginR {
      z-index: 1;
      background-color: #ffffff;
      margin: auto;
      position: absolute;
      border-radius: 2%;
      left: 0;
      right: 0;
      box-shadow: 0px 0px 0px 12px RGB(255 255 255 / 38%);
      width: 22vw;
      top: 50vh;
    }
    .title-dss::before {
      content: ' The platform for \A
      Influencer Marketing';
      white-space: pre-wrap;
    }
    .title-dss {
      position: absolute;
      z-index: 1;
      font-size: 2rem;
      font-weight: bold;
      font-style: italic;
      margin-left: 0 !important;
      margin-right: 0 !important;
      top: 14vh;
    }
    .title-login {
      color: #000000;
      font-size: 30px;
      padding: 25px 0;
      text-align: center;
    }
    #col-form {
      width: 90% !important;
      margin-top: -10%;
    }
    .ant-form-item {
      margin-top: 10px;
      label,
      input {
        color: #015cfe !important;
        border-color: #000000 !important;
        background-color: transparent !important;
        font-family: Ubuntu;
      }
      label {
        top: -18px !important;
      }
      .has-error {
        .ant-form-explain {
          color: #ffffff !important;
        }
      }
    }
    .forgot-password-button {
      span {
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #015cfe;
        width: 100%;
        text-align: center;
      }
    }
    .send-container {
      margin-top: 10px;
    }
    #col-login {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #btn-login {
      border-radius: 7%;
      width: 150px;
      height: 55px;
      font-size: 20px;
      background-color: #2cf5d5 !important;
      border: #2cf5d5;
      color: #04006c !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    .loginR {
      width: 50vw !important;
      top: 45vh !important;
    }
    .title-dss {
      font-size: 1.5rem !important;
      top: 22vh !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .login {
      &::after {
        background-size: 800px;
      }
    }
    .loginR {
      width: 70vw !important;
      top: 400px !important;
      // top: 52vh !important;
    }
    .title-login {
      font-size: 25px !important;
    }
    .title-dss {
      font-size: 1.3rem !important;
      top: 150px !important;
    }
  }
}

.__theme_selected_atv{
  #btn-login {
    color: #ffffff !important;
  }
}
