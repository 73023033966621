// Imports

@import '../../resources/styles/colors';
@import '../../resources/styles/mixins';

// =============================================================================
// INPUTS
// =============================================================================

.ant-form-item {
  .float-label-container {
    // ====================================
    // LABEL
    // ====================================
    .label {
      color: #a6a5a3;
      left: 0;
      bottom: 10px;
      top: auto;

      &.label-float {
        font-weight: bold;
        top: -8px;
      }
    }
  }

  .input-label {
    font-size: pxToRem(12);
    color: $primary-blue;
    font-weight: bold;
  }

  .ant-input-group-addon {
    background-color: transparent;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    padding: 0;
    // border-bottom: 1px solid $light-grey;
    border-radius: 0;
    margin: 0;
    position: absolute;
    top: 8px;
    z-index: 1;
  }


  .addon-after.ant-input-group-wrapper {
    .ant-input-group-addon {
      right: 20px;
    }
    .ant-input-group {
      input {
        padding-right: 30px;
      }
    }
  }

  // ====================================
  // INPUT TYPE TEXT - NUMBER
  // ====================================
  .ant-input,
  .ant-input-number {
    @include input-base-styles();
  }

  .ant-input-number {
    .ant-input-number-input {
      padding: 0;
    }
  }

  // ====================================
  // SELECT
  // ====================================
  .ant-select-selection {
    @include input-base-styles();

    i.anticon.anticon-down.ant-select-arrow-icon {
      color: $light-grey;
    }

    .ant-select-selection__rendered {
      margin: 0;
    }
  }

  .ant-select-selection-selected-value {
    max-width: 90%;
  }

  // ====================================
  // SELECT WITH TAG ITEMS
  // ====================================
  .ant-select {
    width: 100%;

    .ant-select-selection--multiple {
      height: auto;
      .ant-select-selection__rendered {
        height: 36px;
        margin: 0;
      }

      .ant-select-selection__choice {
        border: 1px solid;
        border-radius: 2px;

        .ant-select-selection__choice__remove {
          .anticon {
            color: #fff;
          }
        }
      }
    }

    .ant-select-selection--multiple > ul > li,
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      margin-top: 6px;
      height: 23px;
    }
  }

  // ====================================
  // TEXT AREA
  // ====================================
  &.text-area {
    min-height: 84px;
    margin: 20px 0 30px 0;

    textarea {
      font-size: pxToRem(12);
      border: 1px solid;
      border-radius: 5px;
      padding: 15px 10px;
      height: 84px;
      resize: none;
    }

    .has-error {
      textarea {
        border: solid 1px $pink;
      }
    }

    .input-label {
      font-size: pxToRem(12);
      font-weight: bold;
      top: -25px;
    }

    .label.label-float {
      top: -25px;
    }
  }

  // =============================================
  // HAS VALUE - HAS SUCCESS
  // =============================================
  .has-value,
  .has-success {

    .ant-input-group-addon {
      // border-bottom: 1px solid $primary-blue;
    }
  }

  // =============================================
  // HAS ERROR
  // =============================================
  .has-error {
    .ant-input,
    .ant-select-selection {
      border-color: $pink;
    }

    .ant-select-selection {
      i.anticon.anticon-down.ant-select-arrow-icon {
        color: $pink;
      }
    }

    .ant-form-explain,
    .ant-form-split {
      text-align: left;
      color: $pink;
    }
  }

  .ant-form-explain {
    font-size: pxToRem(12);
    margin-top: 2px;
    text-align: left;
  }

  .ant-input,
  .has-error .ant-input:hover {
    background-color: transparent;
  }
}

// ====================================
// SWITCH
// ====================================
.ant-switch {
  background-color: #ededed;
  border: solid 0.5px #d8d8d8;

  &::after {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  }
}

// =============================================
// INPUT + ADD BUTTON
// =============================================
.field-with-add-button {
  justify-content: space-around;
  align-items: flex-start;
  @include flexbox;

  > div {
    width: 90%;
  }

  .add-button {
    border-radius: 50%;
    padding: 10px;

    &.disabled-button {
      opacity: 0.5;
    }

    &:hover {
      background-color: $pink;
      color: $white;
    }

    i {
      font-size: 11px;
    }
  }
}

// ====================================
// LABEL TITLE
// ====================================
.label-title {
  font-size: pxToRem(12);
  font-weight: bold;
}

// ====================================
// CALENDAR
// ====================================
.ant-calendar-picker {
  margin-top: 10px;
  width: 100%;
}

.ant-form-item-with-help {
  margin-bottom: 10px;
}

.ant-form-item-control {
  line-height: 1;
}

.profile-photo-container {
  .profile-photo-input {
    height: 180px;
  }

  .file-input {
    width: 180px;
    height: 180px;

    .ant-upload.ant-upload-drag {
      width: 180px;
      height: 180px;
      border-radius: 50%;
      border: 2px solid #fff;

      .ant-upload-btn {
        &:before {
          position: absolute;
          content: '';
          border-radius: 50%;
          width: 180px;
          height: 180px;
          border: solid transparent 2px;
          border-right: solid 2px;
          transform: rotate(230deg);
          left: -10px;
          top: -10px;
        }

        &:after {
          position: absolute;
          content: '';
          width: 180px;
          height: 180px;
          border: solid transparent 10px;
          border-right: solid 10px;
          border-radius: 50%;
          transform: rotate(45deg);
          right: -6px;
          z-index: 101;
        }
      }
    }

    .ant-upload-drag-container {
      &:before {
        background-image: url('../images/input_file/border.svg');
        background-position: 0px -116px;
        position: absolute;
        height: 69px;
        width: 180px;
        bottom: 0;
        content: '';
        z-index: 100;
        left: -3px;
      }
    }

    .image-container {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;

      img {
        border-radius: 50%;
        height: 176px;
        width: 176px;
        z-index: 99;
        position: relative;
      }
    }

    .text-preview {
      display: none;
    }
  }

  .input-label {
    position: relative !important;
    top: -28px !important;
    width: 180px;
    left: 0;
    text-align: center;
    color: white !important;
    font-size: 9px;
    z-index: 102;
  }
}
