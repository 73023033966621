// Imports

@import "../../resources/styles/colors";

// =============================================================================
// TAGS
// =============================================================================

.ant-tag {
  font-size: pxToRem(12);
  word-wrap: break-word;
  font-weight: normal;
  white-space: normal;
  text-align: center;
}
