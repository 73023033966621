/* Ant Design Overrides */
@import './variables';
@import './colors';

// DROPDOWN
.ant-dropdown {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
  width: 184px;

  .ant-dropdown-menu {
    padding: 0;

    .ant-dropdown-menu-item {
      border-bottom: solid 1px rgba(216, 216, 216, 0.3);
      font-family: 'Ubuntu', sans-serif;
      align-items: center;
      font-weight: 500;
      padding: 8px 7px;
      font-size: 12px;
      display: flex;
      color: black;

      i {
        margin-right: 5px;
        font-size: 20px;
        color: $pink;

        &.icon-trash {
          margin-right: 8px;
          margin-left: 4px;
        }
      }

      img {
        margin-right: 5px;
        width: 25px;
      }

      &:last-of-type {
        border: none;
      }

      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}

// ====================================
// CALENDAR
// ====================================
.ant-calendar-range {
  .ant-calendar-selected-start-date {
    .ant-calendar-date {
      background: $primary-blue !important;
      line-height: 1.4;
      height: auto;
      width: auto;
      border-radius: 0;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }
  }

  .ant-calendar-disabled-cell .ant-calendar-date:hover {
    color: #fff;
  }

  .ant-calendar-selected-end-date {
    .ant-calendar-date {
      background: $primary-blue !important;
      line-height: 1.4;
      height: auto;
      width: auto;
      border-radius: 0;
      border-bottom-right-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  box-shadow: none;
}

.ant-calendar-picker-container {
  margin-top: 10px;
  z-index: 10000 !important;
}

.ant-calendar-range-picker-input {
  text-align: left !important;
}

.ant-calendar-date:hover {
  background: $primary-blue !important;
  color: #fff;
}

.ant-calendar-range-picker-separator {
  display: none !important;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #d1dafe !important;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $primary-blue !important;
  box-shadow: none !important;
}

/* INPUT */
.ant-layout,
.ant-modal-content,
.ant-modal-root {
  .ant-form-item {
    margin-bottom: 30px;

    &.slider-input {
      &.has-value {
        .input-label {
          font-size: 12px;
          transform: translateY(-85px);
        }
      }
    }

    .ant-checkbox-wrapper {
      span {
        display: inline-block;
        line-height: normal;

        a {
          color: $primary-blue;
        }
      }

      .ant-checkbox-checked {
        &:after {
          border: none;
        }
      }
    }

    .ant-radio-group {
      width: 100%;

      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
        color: $grey;
        height: 32px;
        border: 1px solid $grey;

        &.ant-radio-button-wrapper-checked {
          border: 1px solid;
        }
      }
    }
  }

  .full-phone-field {
    .ant-col {
      &.code-container {
        .flag-image {
          width: 15px;
          position: absolute;
          top: 13px;
          z-index: 2;
        }

        .ant-form-item-control {
          .ant-form-item-children {
            .ant-input {
              text-align: center;
            }
          }
        }
      }
    }

    .separator {
      span {
        top: 8px;
        position: absolute;
      }
    }
  }
}

/* MODAL */

body {
  .ant-select-dropdown {
    z-index: 100000;
  }

  .ant-modal-root {
    .ant-modal-confirm {
      text-align: center;

      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
          float: inherit;

          .ant-btn {
            font-weight: bold;
            border-color: $pink;
            color: $pink;

            &.ant-btn-primary {
              color: #fff;
            }
          }

          .ant-btn-danger {
            background-color: $pink;
            color: white;

            &:hover {
              background-color: $light-grey;
              color: white;
            }
          }
        }
      }
    }
  }

  .ant-modal-mask {
    z-index: 10000;
  }

  .ant-modal-wrap {
    z-index: 10000;

    .ant-modal {
      top: 130px;

      @media screen and (max-width: $xs) {
        width: 100% !important;
        max-width: 100vw !important;
        margin: 0;
        padding: 0;
        height: 100%;
        top: 0;

        .ant-modal-content {
          border-radius: 0;
          min-height: 100vh;
        }
      }

      .ant-modal-close,
      .ant-modal-close .ant-modal-close-x {
        line-height: 50px;
        height: 50px;
        width: 50px;
      }

      .ant-modal-body {
        padding: 0;

        .ant-card {
          border: none;
          border-radius: 5px;

          @media screen and (max-width: $xs) {
            border-radius: 0;
          }

          .ant-card-head,
          .ant-card-head .ant-card-head-title {
            padding: 0;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            @media screen and (max-width: $xs) {
              border-radius: 0;
            }
          }

          .ant-card-head {
            border: none;

            @media screen and (max-width: $xs) {
              box-shadow: $box-shadow;
            }
          }

          .ant-card-body {
            @media screen and (max-width: $sm) {
              padding: 10px;
            }
          }
        }

        .header {
          border-radius: 0;
        }

        .modal-header {
          padding: 18px 18px 18px 50px;
          background: linear-gradient(to left, #380067 0%, #9c1daf 100%);
          color: #fff;
        }
      }
    }
  }

  .ant-card {
    & > .ant-card-head-title {
      box-shadow: $box-shadow;
    }
  }

  .ant-notification {
    z-index: 1000000;
  }

  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-body {
      padding-top: 20px;
      .anticon-question-circle {
        display: none;
      }

      .ant-modal-confirm-content {
        color: #000;
      }
    }

    .ant-modal-confirm-btns {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
  }
}

/* Slider */

.ant-layout {
  .ant-slider,
  .ant-slider:hover {
    .ant-slider-rail {
      height: 12px;
    }

    .ant-slider-track {
      background-color: $primary-blue;
      height: 12px;
    }

    .ant-slider-step {
      height: 12px;

      .ant-slider-dot {
        height: 12px;
        width: 12px;
        top: 0;
      }
    }

    .ant-slider-handle,
    .ant-slider-handle:not(.ant-tooltip-open) {
      height: 24px;
      width: 24px;
      border: 3px solid $primary-blue;
    }

    .ant-slider-mark {
      top: 20px;

      .ant-slider-mark-text {
        &:first-child {
          left: 3% !important;
        }

        left: 97% !important;
        width: 200px;
      }
    }
  }
}

.ant-descriptions {
  .ant-descriptions-item {
    padding: 0 30px 10px 0;
    vertical-align: top;
    text-align: justify;

    .ant-descriptions-item-label {
      font-size: 12px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .ant-descriptions-item-content {
      margin-bottom: 14px;
    }
  }
}

/* Tags Collapse, MISC */
.ant-layout {
  .ant-collapse {
    background-color: transparent;
    border: none;

    .ant-collapse-item {
      border: none;

      .ant-collapse-header {
        border: none;
        font-size: 14px;
        font-weight: bold;
      }

      .ant-collapse-content {
        border: none;
      }
    }
  }

  .addon-before.ant-input-group-wrapper {
    .ant-input-group {
      input {
        padding-left: 30px;
      }
    }
  }

  .addon-after.ant-input-group-wrapper {
    .ant-input-group-addon {
      right: 20px;
    }
    .ant-input-group {
      input {
        padding-right: 30px;
      }
    }
  }

  .ant-input-group {
    position: relative;
  }

  .ant-input-group.ant-input-group-compact > :first-child,
  .ant-input-group.ant-input-group-compact > :last-child {
    border-radius: 0;
  }

}

.ant-tooltip {
  z-index: 100000!important;
}
