$pink: #E1198E;
$purple: #7b40ec;
$grey: #979797;
$light-grey: #a6a5a3;
$lighter-grey: #d8d8d8;
$solid-grey: #636363;

$dark-blue: #1A4CC4;
$light-blue: #1578d5;
$green: #149262;

// =============================================================================
// COLORS
// =============================================================================

// Primary
$primary-blue:       #1442f9;
$primary-pink:       #df249d;
$primary-purple:     #6670e8;
$primary-grey-light: #f6f6f6;
$primary-grey:       #979797;

// Neutral
$white: #fff;
$black: #000;
