.ant-modal-body {
  .title-content {
    h1 {
      font-size: 20px;
    }
  }

  .title-container {
    margin-bottom: 45px;
  }

  .ant-card-head {
    padding: 46px 36px 0px !important;
  }

  .ant-card-body {
    padding: 0px 36px 31px !important;
  }

  .modal-footer {
    .ant-btn {
      margin: 0 auto;
    }
  }

}