@font-face {
  font-family: 'FluvipIcons';
  src: url('../fonts/FluvipIcons.eot?nntrxh');
  src: url('../fonts/FluvipIcons.eot?nntrxh#iefix') format('embedded-opentype'),
    url('../fonts/FluvipIcons.ttf?nntrxh') format('truetype'),
    url('../fonts/FluvipIcons.woff?nntrxh') format('woff'),
    url('../fonts/FluvipIcons.svg?nntrxh#FluvipIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

button i,
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FluvipIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'] {
  position: relative;
}

.icon-exchange:before {
  content: "\e975";
}
.icon-currency:before {
  content: "\e976";
}
.icon-utils:before {
  content: "\e977";
}

.icon-shared-file:before {
  content: '\e970';
}

.icon-open-modal:before {
  content: '\e96f';
}

.icon-indicators:before {
  content: '\e96e';
}

.icon-calendar_content_pdf:before {
  content: '\e96c';
}
.icon-calendar_content:before {
  content: '\e96d';
}
.icon-camera-brief:before {
  content: '\e96b';
}
.icon-finance:before {
  content: '\e969';
}
.icon-excel:before {
  content: '\e968';
}
.icon-social-data:before {
  content: '\e967';
  margin-right: 2px;
}
.icon-share:before {
  content: '\e919';
}
.icon-reel:before {
  content: '\e965';
}
.icon-facebook:before {
  content: '\e906';
}
.icon-instagram:before {
  content: '\e90a';
}
.icon-clear:before {
  content: '\e910';
}
.icon-tiktok:before {
  content: '\e911';
}
.icon-twitter:before {
  content: '\e929';
}
.icon-trash:before {
  content: '\e962';
}
.icon-on:before {
  content: '\e963';
}
.icon-off:before {
  content: '\e964';
}
.icon-video:before {
  content: '\e900';
}
.icon-story:before {
  content: '\e901';
}
.icon-burstStories:before {
  content: '\e96a';
}
.icon-bar-graph:before {
  content: '\e902';
}
.icon-briefcase:before {
  content: '\e903';
}
.icon-family:before {
  content: '\e904';
}
.icon-plus-circle:before {
  content: '\e905';
}
.icon-envelope:before {
  content: '\e907';
}
.icon-highlight:before {
  content: '\e908';
}
.icon-arrow-left:before {
  content: '\e909';
}
.icon-influencer:before {
  content: '\e90b';
}
.icon-plus:before {
  content: '\e90c';
}
.icon-crown:before {
  content: '\e90d';
}
.icon-cross-circle:before {
  content: '\e90e';
}
.icon-check-circle:before {
  content: '\e90f';
}
.icon-check:before {
  content: '\e912';
}
.icon-cross:before {
  content: '\e913';
}
.icon-block:before {
  content: '\e914';
}
.icon-gift:before {
  content: '\e915';
}
.icon-logout:before {
  content: '\e916';
}
.icon-dashboard:before {
  content: '\e917';
}
.icon-whatsapp:before {
  content: '\e918';
}
.icon-menu:before {
  content: '\e91a';
}
.icon-photo:before {
  content: '\e91b';
}
.icon-mirror:before {
  content: '\e91c';
}
.icon-carousel:before {
  content: '\e91d';
}
.icon-genders:before {
  content: '\e91e';
}
.icon-arrow-up:before {
  content: '\e91f';
}
.icon-arrow-right:before {
  content: '\e920';
}
.icon-arrow-down:before {
  content: '\e921';
}
.icon-laptop:before {
  content: '\e922';
}
.icon-money-bag:before {
  content: '\e923';
}
.icon-shining-star:before {
  content: '\e924';
}
.icon-art:before {
  content: '\e925';
}
.icon-chip:before {
  content: '\e926';
}
.icon-mask:before {
  content: '\e927';
}
.icon-plane:before {
  content: '\e928';
}
.icon-post:before {
  content: '\e92a';
}
.icon-hourglass:before {
  content: '\e92b';
}
.icon-file:before {
  content: '\e92c';
}
.icon-gear:before {
  content: '\e92d';
}
.icon-link:before {
  content: '\e92e';
}
.icon-user:before {
  content: '\e92f';
}
.icon-igtv:before {
  content: '\e930';
}
.icon-wedding-rings:before {
  content: '\e931';
}
.icon-diamond:before {
  content: '\e932';
}
.icon-book:before {
  content: '\e933';
}
.icon-bag:before {
  content: '\e934';
}
.icon-star:before {
  content: '\e935';
}
.icon-paw:before {
  content: '\e936';
}
.icon-sticker:before {
  content: '\e937';
}
.icon-click:before {
  content: '\e938';
}
.icon-expand:before {
  content: '\e939';
}
.icon-megaphone:before {
  content: '\e93a';
}
.icon-rattle:before {
  content: '\e93b';
}
.icon-bookmark:before {
  content: '\e93c';
}
.icon-martini:before {
  content: '\e93d';
}
.icon-dumbell:before {
  content: '\e93e';
}
.icon-cutlery:before {
  content: '\e93f';
}
.icon-duplicate:before {
  content: '\e940';
}
.icon-tv:before {
  content: '\e941';
}
.icon-return:before {
  content: '\e942';
}
.icon-box:before {
  content: '\e943';
}
.icon-global:before {
  content: '\e944';
}
.icon-camera:before {
  content: '\e945';
}
.icon-info:before {
  content: '\e946';
}
.icon-squares:before {
  content: '\e947';
}
.icon-search:before {
  content: '\e948';
}
.icon-star-graph:before {
  content: '\e949';
}
.icon-fanpage:before {
  content: '\e94a';
}
.icon-heart-graph:before {
  content: '\e94b';
}
.icon-line-graph:before {
  content: '\e94c';
}
.icon-eye:before {
  content: '\e94d';
}
.icon-heart:before {
  content: '\e94e';
}
.icon-like:before {
  content: '\e94f';
}
.icon-comment:before {
  content: '\e950';
}
.icon-follower:before {
  content: '\e951';
}
.icon-female:before {
  content: '\e952';
}
.icon-male:before {
  content: '\e953';
}
.icon-edit:before {
  content: '\e954';
}
.icon-cellphone:before {
  content: '\e955';
}
.icon-trash1:before {
  content: '\e956';
}
.icon-phone:before {
  content: '\e957';
}
.icon-calendar:before {
  content: '\e958';
}
.icon-pin:before {
  content: '\e959';
}
.icon-download:before {
  content: '\e95a';
}
.icon-upload:before {
  content: '\e95b';
}
.icon-bell:before {
  content: '\e95c';
}
.icon-linkedin:before {
  content: '\e95d';
}
.icon-youtube:before {
  content: '\e95e';
}
.icon-timer:before {
  content: '\e95f';
}
.icon-stopwatch:before {
  content: '\e960';
}
.icon-hashtag:before {
  content: '\e961';
}
.icon-live:before {
  content: '\e966';
}

.icon-activewear:before{
  content: '\e975'
}
.icon-agriculture:before{
  content: '\e976'
}
.icon-art-design:before{
  content: '\e977'
}
.icon-automotive:before{
  content: '\e978'
}
.icon-beauty-cosmetics:before{
  content: '\e979'
}
.icon-beer-wine-spirits:before{
  content: '\e97a'
}
.icon-business-careers:before{
  content: '\e97b'
}
.icon-camera-photography:before{
  content: '\e97c'
}
.icon-cars-motorbikes:before{
  content: '\e97d'
}
.icon-clothes-shoes-handbags-accessories:before{
  content: '\e97e'
}
.icon-coffee-tea-beverages:before{
  content: '\e97f'
}
.icon-courier:before{
  content: '\e980'
}
.icon-digital:before{
  content: '\e981'
}
.icon-drinks:before{
  content: '\e982'
}
.icon-education:before{
  content: '\e983'
}
.icon-electronics-computers:before{
  content: '\e984'
}
.icon-electronics:before{
  content: '\e985'
}
.icon-entertainment:before{
  content: '\e986'
}
.icon-events:before{
  content: '\e987'
}
.icon-financial-insurance:before{
  content: '\e988'
}
.icon-fitness-yoga:before{
  content: '\e989'
}
.icon-food:before{
  content: '\e98a'
}
.icon-friends-family-relationships:before{
  content: '\e98b'
}
.icon-gaming:before{
  content: '\e98c'
}
.icon-health-beauty:before{
  content: '\e98d'
}
.icon-healthcare-medicine:before{
  content: '\e98e'
}
.icon-healthy-lifestyle:before{
  content: '\e98f'
}
.icon-home-decor-furniture-garden:before{
  content: '\e990'
}
.icon-home-decor:before{
  content: '\e991'
}
.icon-home:before{
  content: '\e992'
}
.icon-humor:before{
  content: '\e993'
}
.icon-jewellery-watches:before{
  content: '\e994'
}
.icon-kids:before{
  content: '\e995'
}
.icon-luxury-goods:before{
  content: '\e996'
}
.icon-music:before{
  content: '\e997'
}
.icon-personal-hygiene:before{
  content: '\e998'
}
.icon-pet:before{
  content: '\e999'
}
.icon-pets:before{
  content: '\e99a'
}
.icon-professional:before{
  content: '\e99b'
}
.icon-real-state-construction:before{
  content: '\e99c'
}
.icon-restaurants-food-grocery:before{
  content: '\e99d'
}
.icon-shopping-retail:before{
  content: '\e99e'
}
.icon-sport:before{
  content: '\e99f'
}
.icon-sports:before{
  content: '\e9a0'
}
.icon-stationery:before{
  content: '\e9a1'
}
.icon-telecomunications:before{
  content: '\e9a2'
}
.icon-television-film:before{
  content: '\e9a3'
}
.icon-tobacco-smoking:before{
  content: '\e9a4'
}
.icon-toys-children-baby:before{
  content: '\e9a5'
}
.icon-travel-tourism-aviation:before{
  content: '\e9a6'
}
.icon-turism:before{
  content: '\e9a7'
}
.icon-wardrobe-accesories:before{
  content: '\e9a8'
}
.icon-wedding:before{
  content: '\e9a9'
}
