// Imports
@import '../../../resources/styles/mixins';
@import '../../../resources/styles/colors';

// =============================================================================
// HEADER
// =============================================================================

.header {
  @include flexbox;
  justify-content: center;
  height: 58px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    .img-logo {
      width: 110px;
      height: 30px;
      background-image: url('../../../resources/images/themes/main/logo-horizontal.svg');
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
    }

    .img-alpina {
      width: 70px;
    }
  }
}

// Logged
.logged {
  .header {
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
  }
}
