// =============================================================================
// PARAGRAHPS
// =============================================================================

p {
  font-size: pxToRem(16);
  font-weight: normal;

  strong {
    font-weight: bold;
  }
}

p.small {
  font-size: pxToRem(14);
}
