// Imports

@import '../../../../resources/styles/variables';
@import '../../../../resources/styles/colors';
@import '../../../../resources/styles/mixins';

// =============================================================================
// SIDER
// =============================================================================

.ant-layout-sider {
  background-color: $primary-grey-light;
  position: fixed;
  height: 100vh;

  &.ant-layout-sider-zero-width {
    .logout,
    .config {
      display: none;
    }
  }

  .menu-header {
    height: 73px;
    border-right: solid 1px $primary-grey-light;
    border-bottom: 1px solid #dcdcdc;
    justify-content: space-between;
    background-color: $white;
    padding: 25px 16px 25px;
    align-items: center;
    margin-top: 58px;
    @include flexbox;

    h2 {
      color: $primary-blue;
      line-height: 1.1;
    }

    .anticon-menu {
      display: none;
    }
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-menu-submenu {
    background-color: $primary-grey-light;
    box-shadow: none;
  }

  .ant-menu {
    background-color: $primary-grey-light;
    padding: 31px 25px 0;

    .ant-menu-submenu,
    .ant-menu-item {
      border-bottom: 1px solid $light-grey;
      padding: 0 !important;
      color: $light-grey;
      @include flexbox;
      min-height: 65px;
      margin: 0;

      i {
        margin-right: 10px;
        font-size: 20px;
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #000;
      }
      &.ant-menu-submenu-item-selected {
        background-color: transparent;
        color: #000;
      }

      &.ant-menu-item-selected {
        border-bottom: 5px solid;
        font-weight: bold;
      }

      &.logout {
        bottom: 0;
      }

      &.config {
        bottom: 59px;
      }

      &.logout,
      &.config {
        padding: 0 25px !important;
        border-bottom: none;
        position: absolute;
        min-width: 100%;
      }

      span.menu-title {
        align-items: center;
        display: flex;
        width: 100%;
      }
    }

    .ant-menu-submenu {
      padding-top: 10px !important;
      padding-left: 0;
      display: block;
    }
    .ant-menu-submenu-arrow::before{
      background: #000!important;
      color: #000!important;
    }
    .ant-menu-submenu-arrow::after{
      background: #000!important;
      color: #000!important;
    }
    // .ant-menu-submenu-title{
    //   background-color: transparent;
    //   color: #000!important;
    // }
  }

  .ant-menu-sub {
    padding: 5px 0;
    padding-left: 10px;
  }

  .ant-menu-submenu-title {
    padding: 0 !important;
    :hover{
      background-color: transparent;
      color: #000;
    }

  }

  .ant-menu-item:last-child {
    border: none;
  }
}

// Collapsed
.ant-menu-inline-collapsed {
  width: 100%;

  span.menu-title {
    justify-content: center;
    text-align: center;
  }

  .menu-text {
    display: none;
  }
}

.ant-layout-sider-collapsed {
  .menu-header {
    justify-content: center;

    .anticon-menu {
      display: block;
    }

    h2,
    .anticon-close {
      display: none;
    }
  }

  .ant-menu-submenu,
  .ant-menu-item {
    i {
      margin: 0 !important;
    }
  }
  .ant-menu-submenu{
    i{
      padding-top: 10px !important;
    }
  }
}
