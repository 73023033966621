
@import "../../../resources/styles/colors";

.otp-container {
  justify-content: center;

  .otp-input {
    width: 40px !important;
    margin: 5px;
    border: none;
    border-bottom: 1px solid $primary-blue;
  }
}
