// =============================================================================
// TITLES
// =============================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: pxToRem(30);
}

h2 {
  font-size: pxToRem(20);
}

h3 {
  font-size: pxToRem(18);
}

h4 {
  font-size: pxToRem(16);
}

h5 {
  font-size: pxToRem(14);
}

h6 {
  font-size: pxToRem(12);
}
